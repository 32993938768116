import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import AxiosInstance from "../../../helpers/axiosInstance";
import {UserProvider} from "../../../hoc/userProvider";
import {BiChat} from "react-icons/bi";
import Moment from "react-moment";

const ComplaintsPage = () => {
    const [complaints,setComplaints] = useState([]);
    const [pg,setPg] = useState(0);

    function changePage(e) {

        if(e<0){
            setPg(0);
        }
        setPg(e);
    }
    const userInfo = React.useContext(UserProvider);
    const getAllComplaints = useCallback(()=>{
        AxiosInstance.get( `/sup/misc/complaints/?pg=${pg}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setComplaints(res.data.data.docs)
            })
            .catch(err=>{

            })
    },[pg,userInfo.token])

    useEffect(()=>{
        getAllComplaints()
    },[getAllComplaints])
    return (
        <AdminLayout title={'Complaints'}>
            <div className="Gcenter-all">
                {
                    complaints.length>0?
                        complaints.map((e)=><div className={'darkCard'} key={e._id}>
                           <div className="grid">
                               <div className="grid-item2_4">
                                   <p className="h8 greyText">Title</p>
                                   <h6 className="h6 mgT2">{e.title}</h6>
                                   <p className="h8 greyText mgT2">Subject</p>
                                   <h6 className="h7 greenText">{e.subject}</h6>
                               </div>
                               <div className="grid-item2_4">
                                   <p className="h8 greyText">Message</p>
                                   <h6 className="h6 mgT2">{e.message}</h6>
                                   <div className=" mgT2">
                                       <p className="h8 greyText">Date</p>
                                       <h6 className="h6"><Moment format={'MMMM DD YYYY HH:mm::ss'}>{e.createdAt}</Moment></h6>
                                   </div>
                               </div>
                           </div>
                        </div>):


                        <div className={'textCenter'}>
                            <BiChat className={'greyText'} size={48}/>
                        <h6 className="h6">No Complaint Found</h6>
                        </div>
                }

                <div className="paginator">
                    <ul>
                        <li onClick={pg!==0?()=>changePage((pg - 1)):null} className={pg===0?'inactiveP':null}>Prev</li>
                        <li>&nbsp;</li>
                        <li onClick={complaints.length!==0?()=>changePage((pg + 1)):null} className={complaints.length === 0|| complaints.length<4?'inactiveP':null} >Next</li>
                    </ul>
                </div>
            </div>
        </AdminLayout>
    );
};

export default ComplaintsPage;