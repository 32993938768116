import React, {useState} from 'react';
import ClientDash from "../../layout/ClientLayout";
import TransactionCard from "../../components/transactionCard";
import Modal from "../../components/Modal";

const Wallet = () => {
    const [showAdd,setShowAdd] = useState(false);
    return (
        <ClientDash title={'Wallets'}>
            <div className="grid mgT2">
                <div className="grid-item1_3">
                    <div className="walletCard">
                        <p className="h7 greyText">Card Number</p>
                        <h6 className="h5 whiteText">1234 4353 24242 242</h6>
                        <div className="mgT4 grid">
                            <div className="grid-item2_4">
                                <p className="h8 greyText">Holder's Name</p>
                                <h6 className="h6 greyText mgT1">Jon Doe</h6>

                            </div>
                            <div className="grid-item2_4 text_right">
                                <p className="h8 greyText">Expiry</p>
                                <h6 className="h7 greyText mgT1">09/2022</h6>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item1_3">
                    <div className="walletCard">
                        <p className="h7 greyText">Card Number</p>
                        <h6 className="h5 whiteText">1234 4353 24242 242</h6>
                        <div className="mgT4 grid">
                            <div className="grid-item2_4">
                                <p className="h8 greyText">Holder's Name</p>
                                <h6 className="h6 greyText mgT1">Jon Doe</h6>

                            </div>
                            <div className="grid-item2_4 text_right">
                                <p className="h8 greyText">Expiry</p>
                                <h6 className="h7 greyText mgT1">09/2022</h6>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item1_3">
                    <div className="walletCard">
                        <p className="h7 greyText">Card Number</p>
                        <h6 className="h5 whiteText">1234 4353 24242 242</h6>
                        <div className="mgT4 grid">
                            <div className="grid-item2_4">
                                <p className="h8 greyText">Holder's Name</p>
                                <h6 className="h6 greyText mgT1">Jon Doe</h6>

                            </div>
                            <div className="grid-item2_4 text_right">
                                <p className="h8 greyText">Expiry</p>
                                <h6 className="h7 greyText mgT1">09/2022</h6>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showAdd?<Modal closeModal={setShowAdd}>
                    <h5 className="h5 greyText mgB2">Add a card</h5>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={"Holder's Name"}/>
                    </div>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={"**** **** **** ***"}/>
                    </div>
                    <div className="form-group">

                        <div className="form-input-box">
                            <input type="text" className={'form-input'} placeholder={"Expiry Date"}/>
                        </div>
                        <div className="form-input-box">
                            <input type="text" className={'form-input'} placeholder={"CVV"}/>
                        </div>
                    </div>
                    <button className="btn btn-sec">Proceed</button>
                </Modal>:null
            }
            <div className="mgT4">
                <button onClick={()=>setShowAdd(true)} className="btn btn-primary">Add Card</button>
            </div>
            <div className="mgT4 grid">
                <div className="grid-item3_4">
                    <h5 className="h6 greyText">Recent Transactions</h5>
                    <div className="mgT2 trx-area">
                        <TransactionCard/>
                        <TransactionCard/>
                        <TransactionCard/>
                        <TransactionCard/>
                        <TransactionCard/>
                    </div>

                    <div className="mgT2">
                        <span className="h6 link">Prev</span>
                        <span className="h6 link mgL2">1</span>
                        <span className="h6 link mgL2">Next</span>
                    </div>
                </div>
            </div>
        </ClientDash>
    );
};

export default Wallet;