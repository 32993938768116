import React, {useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import AxiosInstance from "../../helpers/axiosInstance";
import {UserProvider} from "../../hoc/userProvider";
import {toast, ToastContainer} from "react-toastify";
const AdminSettings = () => {
    const [password,setPassword] = useState('');
    const [rePassword,setRePassword] = useState('');

    const userInfo = React.useContext(UserProvider);
    function  changePassword(e){
        e.preventDefault();
        const user_ = JSON.parse(userInfo.user)
       if (password===rePassword){
           AxiosInstance.post(`/sup/misc/change-password/${user_._id}`,{password},{
               headers:{
                   authorization:userInfo.token,
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
               }
           })
               .then((res)=>{
                   setPassword('');
                   setRePassword('')
                   toast.success(res.data.msg)
               }).catch(err=>{
               toast.error(err.response.data.msg)
           })
       }else{
           toast.error("Passwords must match...")
       }
    }
    return (
        <AdminLayout>
            <h3 className="h4">Settings</h3>
            <div className="mgT4">
                <hr className="hr"/>
                <ToastContainer/>
                <h5 className="h5 mgT2">Bank Settings
                </h5>
                <form onSubmit={changePassword}>
                    <div className="grid mgT2">
                        <div className="grid-item2_4 ">
                            <div className="form-input-box">

                                <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder={'Password'} />
                            </div>
                            <div className="form-input-box">

                                <input value={rePassword} onChange={(e)=>setRePassword(e.target.value)} placeholder={'Retype password'} />
                            </div>
                            {/*<div className="form-input-box">*/}

                            {/*    <textarea style={{height:'10rem'}} placeholder={'Description'} />*/}
                            {/*</div>*/}
                            <div className="mgT2">
                                {/*<button className="btn btn-blue ">Add Settings</button>*/}
                                <button className="btn btn-green ">Save Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </AdminLayout>
    );
};

export default AdminSettings;