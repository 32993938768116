import AxiosInstance from "../axiosInstance";

export function pinRequest (id,meta,tk) {
    AxiosInstance.post(`/acc/pin/req/${id}`,{meta},{
headers:{
    authorization: tk
}
    })
        .then((res)=>{
            return res.data
        })
        .catch(err=>{
            console.log(err)
        })
}