import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import AxiosInstance from "../../../helpers/axiosInstance";
import {numberWithCommas} from "../../../helpers/misc";

const PinGenerate = () => {
    const [pinRequests,setPinRequests] = useState([])
    const [pg,setPg] = useState(1)
    const [totalPages,setTotalPages] = useState(0)
    const [limit] = useState(10)

    function changepg(e) {

        if(e<0){
            setPg(0);
        }
        setPg(e);
    }
    const getPinRequests = useCallback(()=>{
        AxiosInstance.get(`/sup/acc/pinReqs/?pg=${pg}&limit=${limit}`)
            .then(res=>{
                console.log(res.data)
                setPinRequests(res.data.pinRequests)
                setTotalPages(res.data.totalPages)
            })
            .catch(err=>{
                console.log(err)
            })
    },[pg,limit])
    useEffect(()=>{
        getPinRequests();
    },[getPinRequests])

    function RequestItem({e}) {
        let item = e?.meta? JSON.parse(e?.meta):null
        return <div className={'requestCard'}>
           <div className="grid">
               <div className="grid-item1_4">
                   <p className="h8 greyText">Owner</p>
                   <h6 className="h6  mgT1">{e.owner.firstName} {e.owner.lastName}</h6>
                   <p className="h8 greyText mgT2">Acc. No.</p>
                   <h6 className="h6  mgT1">{e.owner?.accNo}</h6>
               </div>
               <div className="grid-item1_4">
                   <p className="h8 greyText">Status</p>
                   <h6 className="h6  mgT1">{e.status}</h6>
               </div>
               <div className="grid-item1_4">
                   <p className="h8 greyText">Destination Account No.</p>
                   <h6 className="h6  mgT1">{item?item.beneficiary_account:""}</h6>
                   <p className="h8 greyText mgT1">Destination Account Name</p>
                   <h6 className="h6  mgT1">{item?item.beneficiary_name:""}</h6>
               </div>
               <div className="grid-item1_4">
                   <p className="h8 greyText">Amount</p>
                   <h6 className="h6  mgT1">&#8364;{item?numberWithCommas(item.amount):""}</h6>

               </div>
           </div>
        </div>
    }
    return (
        <AdminLayout title={'Pin Requests'}>
            <div className="Gcenter-all">
                {
                    pinRequests.length>0?pinRequests.map((e,i)=>{
                     return <RequestItem key={e._id} e={e}/>
                    }):<>
                        <h6 className="h6 ">No Requests Found</h6>
                    </>
                }
                <div className="paginator">
                    <ul>
                        <li onClick={pg!==1?()=>changepg((pg - 1)):null} className={pg===0?'inactiveP':null}>Prev</li>
                        <li>{pg}</li>
                        {totalPages!==pg?<li onClick={()=>changepg(totalPages)}>... {totalPages}</li>:null}
                        <li onClick={pinRequests.length!==totalPages?()=>changepg((pg + 1)):null} className={pinRequests.length === 0|| pinRequests.length<4?'inactiveP':null} >Next</li>
                    </ul>
                </div>
            </div>
        </AdminLayout>
    );
};

export default PinGenerate;