import React, {useCallback, useEffect, useState} from 'react';
import Modal from "../../../components/Modal";

import axiosInstance from "../../../helpers/axiosInstance";
import {UserProvider} from "../../../hoc/userProvider";
import {toast} from "react-toastify";
import Loader from "../../../components/loader";
import Moment from "react-moment";
import {Link} from "react-router-dom";


const UserCard = ({data,list,setList,id,currentPage,deleteUser,suspendUser}) => {
    const userInfo = React.useContext(UserProvider);
    const [viewModal,setViewModal] = React.useState(false);
    const [viewDeleteModal,setViewDeleteModal] = React.useState(false);
    const [email,setEmail] = React.useState(data.email|| " ")
    const [loading,setLoading] = useState(false);
    const [statuses] = useState(["pending","suspended","active","closed"]);
    const [status,setStatus] = useState(data.status)
    function editUser(e) {

        e.preventDefault();
        const formData ={
            email
        }
        setLoading(true)
        axiosInstance
            .post(`/sup/acc/update/user/${data._id}`,{
           ...formData},{
            headers:{
                authorization: userInfo.token
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setTimeout(()=>{
                    setViewModal(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.success(err.response.data.msg);
            })
    }
    // const verify = useCallback(()=> {
    //     axiosInstance
    //         .post(`/sup/acc/verify/acc/${data._id}`,{
    //             status
    //         },{
    //             headers:{
    //                 authorization: userInfo.token
    //             }
    //         })
    //         .then(res=>{
    //             setLoading(false)
    //             console.log(res.data)
    //             toast.success(res.data.msg);
    //         })
    //         .catch(err=>{
    //             setLoading(false)
    //             toast.success(err.response.data.msg);
    //         })
    // },[data._id, status, userInfo.token])
    // useEffect(()=>{
    //
    // },[verify,status])
    function verify(value) {
        axiosInstance
            .post(`/sup/acc/verify/acc/${data._id}`,{
                status: value
            },{
                headers:{
                    authorization: userInfo.token
                }
            })
            .then(res=>{
                setLoading(false)
                console.log(res.data)
                toast.success(res.data.msg);
            })
            .catch(err=>{
                setLoading(false)
                toast.success(err.response.data.msg);
            })
    }


    return (
            <tr>
                <td>
                    {viewModal?
                    <Modal closeModal={setViewModal}>
                        {loading?<Loader/>:null}
                    <h3 className="h5 whiteText">Edit User</h3>
                    <form onSubmit={editUser} className="form profile_form fullWidth flex_1">
                        <div className="form-input-box">
                            <input value={email} className={'form-input'} onChange={(e)=>setEmail(e.target.value)} placeholder={'email'}/>
                        </div>

                        <button className="btn btn-green mgT2">Save</button>
                    </form>
                </Modal>:null
            }{
                viewDeleteModal?
                    <Modal closeModal={setViewDeleteModal}>
                   <h3 className="h5 whiteText">Are You sure you want to delete?</h3>
                    <p className='h6 whiteText mgT1'>Once you delete it cannot be recovered</p>
                         <button onClick={()=>deleteUser( data,setViewDeleteModal)} className='btn btn-red mgT1'>Delete User</button>
                </Modal>:null
            }
            </td>
              <td className="h6">{(currentPage * 4) + id + 1}
              </td><td className="h6">{data?.email}</td>
              {/*<td className="h6">{data?.firstName}</td>*/}
              {/*<td className="h6">{data?.lastName}</td>*/}
              <td className="h6"><Moment format={'MMM DD YYYY'}>{data?.createdAt}</Moment></td>
              {/*<td className="h6">{data?.wallet?.address}</td>*/}
              {/*<td className="h6">{data?.address}</td>*/}
                {
                    setList===undefined?null:

                        (
                            <td className='btn-td'>
                       <span className='btn btn-green' onClick={()=>setViewModal(!viewModal)}>
                           Edit User
                       </span>
                                <span className='btn btn-red mg1' onClick={()=>setViewDeleteModal(!viewDeleteModal)}>
                           Delete
                       </span>
                                <Link className='h6 btn btn-blue' to={`/admin/acc/statement/${data._id}`}>
                           statement
                       </Link>
                                <div className="form-input-box mgL2" style={{display:'inline-block'}}>
                                    <select className="form-input" value={status} onChange={(e)=>{
                                        setStatus(e.target.value);
                                        verify(e.target.value)
                                    }}>
                                        {
                                            statuses.map((e,i)=><option key={i}>{e}</option>)
                                        }
                                    </select>
                                </div>

                                {/*<button className="btn mgL2" onClick={verify}>{status===false?"enable":"disable"}</button>*/}
                            </td>
                        )
                }
        </tr>

    );
};

export default UserCard;