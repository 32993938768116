import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../helpers/axiosInstance";
import {UserProvider} from "../../hoc/userProvider";
import {toast, ToastContainer} from "react-toastify";
import AxiosInstance from "../../helpers/axiosInstance";
import Modal from "../../components/Modal";
import Loader from "../../components/loader";

const SwiftCodes = () => {
    const [swiftCodes,setSwiftCodes] = useState([]);
    const [loading,setLoading] = useState(false)
    const [delModal,setDel] = useState(false)
    const [updateModal,setUpdate] = useState(false)
    const [showWalletMod,setShowWalletMod] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [wallAdd,setWallAdd] = useState('');
    const [current,setCurrent] = useState({
        code:"",
        address:"",
        bank:""
    });
    const [createData,setCreateData] = useState({
        code:"",
        address:"",
        bank:""
    });

    const userInfo = React.useContext(UserProvider);

    const getWalletAddresses = useCallback(()=>{
         axiosInstance.get('/sup/acc/all/swifts',{
             headers:{
                 authorization: userInfo.token
             }
         })
             .then(res=>{
                 console.log(res.data)
                 setSwiftCodes(res.data.codes)
             })
             .catch(err=>{
                 toast.error(err.response.data.msg)
             })
    },[userInfo.token])

    function createNewAddress() {
        setLoading(true)
        AxiosInstance.post('/sup/misc/create/swift',{...createData})
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setWallAdd('');
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowWalletMod(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    function deleteAddress() {
        setLoading(true)
        AxiosInstance.delete(`/sup/misc/swift/${current?._id}`,)
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setDel(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    function updateAddress() {
        setLoading(true)
        AxiosInstance.post(`/sup/misc/update/swift/`,current,)
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setUpdate(false)
                },1000)


            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data)
            })
    }
    useEffect(()=>{
        getWalletAddresses();
        return ()=>{}
    },[getWalletAddresses,refresh])
    return (
        <AdminLayout title={'Swift Codes'}>
          <div className="Gcenter-all">
              <button className="btn btn-blue mgT4" onClick={()=>setShowWalletMod(true)}>Add Swift Code</button>
              {
                  loading?<Loader/>:null
              }
              {
                  delModal?
                      <Modal closeModal={setDel}>
                          <h5 className="h5 ">Are you sure you want to delete?</h5>
                          <button className="btn btn-red mgT2" onClick={deleteAddress} >Delete</button>
                      </Modal>
                      :null
              }
              {
                  updateModal?
                      <Modal closeModal={setUpdate}>
                          <h5 className="h5 ">Update Wallet</h5>
                          <div className="mgT2">
                              <div className="form-input-box">
                                  <input className={'form-input'} type="text" placeholder={'address'} value={current.address||""} onChange={e=>{
                                      let curr_ = {...current};
                                      curr_.address = e.target.value;
                                      console.log(e.target.value,curr_)
                                      setCurrent(curr_);
                                  }
                                  }/>
                              </div>

                              <div className="form-input-box">
                                  <input className={'form-input'} type="text" placeholder={'Swift Code'} value={current.code||""} onChange={e=>{
                                      let curr_ = {...current};
                                      curr_.code = e.target.value;
                                      console.log(e.target.value,curr_)
                                      setCurrent(curr_);
                                  }
                                  }/>
                              </div>

                              <div className="form-input-box">
                                  <input className={'form-input'} type="text" placeholder={'Bank'} value={current.bank||""} onChange={e=>{
                                      let curr_ = {...current};
                                      curr_.bank = e.target.value;
                                      console.log(e.target.value,curr_)
                                      setCurrent(curr_);
                                  }
                                  }/>
                              </div>

                          </div>
                          <button className="btn btn-blue mgT2" onClick={updateAddress} >Update</button>
                      </Modal>
                      :null
              }
              <ToastContainer/>
              <div className="grid mgT4">
                  <div className="grid-item3_4">
                      {
                          showWalletMod?<Modal closeModal={setShowWalletMod}>

                              <h5 className="h5 ">Create new Swift Code</h5>

                              <label  className="h7  greyText mgT4">Swift Code</label>
                              <div className="form-input-box ">
                                  <input value={createData.code||""}  className={'form-input'} onChange={e=> {
                                      const cre_ = {...createData}
                                      cre_.code = e.target.value;
                                      setCreateData(cre_)
                                  }} type="text" placeholder={'swift code'}/>
                              </div>
                              <label  className="h7 grey-text lightColor">Bank</label>
                              <div className="form-input-box mgT1">
                                  <input value={createData.bank}  className={'form-input'} onChange={e=> {
                                      const cre_ = {...createData}
                                      cre_.bank = e.target.value;
                                      setCreateData(cre_)
                                  }} type="text" placeholder={'bank'}/>

                              </div>
                              <label  className="h7 grey-text mgT1 lightColor">address</label>
                              <div className="form-input-box">
                                  <input value={createData.address}  className={'form-input'} onChange={e=> {
                                      const cre_ = {...createData}
                                      cre_.address = e.target.value;
                                      setCreateData(cre_)
                                  }} type="text" placeholder={'address'}/>

                              </div>
                              <button onClick={createNewAddress} className="btn btn-blue">Add new</button>
                          </Modal>:null
                      }
                      {
                          swiftCodes.length>0?
                              swiftCodes.map(e=><div key={e._id} className={'darkCard grid'}>
                                  <div className="grid-item3_4">
                                      <p className={'h7 grey-text'}>Code</p>
                                      <h6 className={'h6 '}>{e.code}</h6>
                                      <p className={'h7 grey-text'}>Bank</p>
                                      <h6 className={'h6 '}>{e.bank}</h6>
                                  </div>
                                  <div className="grid-item2_4">
                                      <p className={'h7 grey-text'}>address</p>
                                      <h6 className={'h6'}>{e.address}</h6>
                                  </div>
                                  <div className="display_flex mgL2">
                                      <button className="btn btn-blue" onClick={()=>{
                                          setCurrent(e);
                                          setUpdate(true)
                                      }}>Edit</button>
                                      <button className="btn btn-red mgL2" onClick={()=>{
                                          setCurrent(e);
                                          setDel(true)
                                      }}>Delete</button>
                                  </div>
                              </div>)
                              :<div className={'empty h6'}>No available Swift codes</div>
                      }

                  </div>
              </div>
          </div>
        </AdminLayout>
    );
};

export default SwiftCodes;