import React, {useEffect, useRef} from 'react';
import axios from "../../helpers/axiosInstance";
import {Link} from "react-router-dom";
import Loader from "../../components/loader";
import {toast, ToastContainer} from "react-toastify";
import {handleValidate} from "../../helpers/validateForm";
import InlinLoader from "../../hoc/Inlin-loader";
import {createCaptcha} from "./captcha";
// import {LoadCanvasTemplateNoReload, loadCaptchaEnginge} from "react-simple-captcha";
const ResetPassword = () => {
    const [email,setEmail] = React.useState('');
    const canvas = useRef(null)
    const [characters,setCharacters] = React.useState('');
    const [char_,setChar] = React.useState('');
    const [loading,setLoading] = React.useState(false);

   function resetPassword(e){
       setLoading(true);
        e.preventDefault();
         let data = {
             email
         }
       if(char_===characters && handleValidate(email,"email")){
           axios.post('/auth/reset/',data)
               .then(res => {
                   setLoading(false);
                   toast.success(res.data.msg)
               })
               .catch(err=> {
                   setLoading(false);

                   toast.error(err.response.data.msg)
               })
       }else{
           setLoading(false);
           toast.info("Check Characters, and make sure user is valid")
       }
    }
    useEffect(()=>{
       let {code} = createCaptcha(canvas.current)
        setChar(code)
    },[])

       return (
           <div className='authBox'>
               {
                   loading?<Loader/>:null
               }

                <ToastContainer/>

                   <div className="authBox-card width60 mgT8">
                   <form onSubmit={resetPassword} className="">
                      <div className="pd2">
                          <h4 className="h4 mgT2">New Password Request</h4>
                      </div>
                       <div className="mgT4 lightArea_ pd2">

                           <h6 className="h6">E-Banking user:</h6>
                           <p className="h6 greyText mgT1">Please enter your user and the displayed characters</p>
                           <h6 className="h6 mgT4">All other users(e.g EXpertViews or Form Center)</h6>
                           <p className="h6 greyText mgT1">Please enter your user and the displayed characters</p>
                           <div className="form-group">
                               <label className="h5 greyText grid-item2_4">User</label>
                               <div className="form-input-box mgL2 grid-item2_4">
                                   <input type={'email'}  value={email}  onChange={(e)=>{


                                       setEmail(e.target.value)}} className={handleValidate(email,'email')?"form-input form-validate":"form-input form-validate-err"} placeholder={'Email Address'}/>
                               </div>
                           </div>

                           <div className="form-group">
                               <label className="h5 greyText grid-item2_4 nowhitespace">Enter characters*</label>
                               <div className="grouped align-center" >
                                   <div className="form-input-box" >
                                       <canvas className="form-input" ref={canvas}/>
                                   </div>
                                   <div className="form-input-box" >
                                       <input  value={characters}  onChange={(e)=>setCharacters(e.target.value)} className={"form-input"} />
                                   </div>
                               </div>
                           </div>

                       </div>
                       <div className="form">
                           {loading?<InlinLoader/> :
                               <div className="mgT4 display_flex align-center just-btw">
                                   <Link to={'/'} className={'btn btn-grey'} style={{width:'45%'}}>Quit</Link>
                                  <button  className="btn btn-primary sans h5"  style={{width:'45%'}}>Next</button>
                               </div>}
                       </div>
                         </form>
                   </div>
           </div>
       );
   // }
};

export default ResetPassword;