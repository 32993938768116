import React, {useCallback, useEffect, useRef, useState} from 'react';
import ClientDash from "../../layout/ClientLayout";
import {useLocation} from "react-router-dom";
import Moment from "react-moment";
import {numberWithCommas} from "../../helpers/misc";
import jsPDF from "jspdf";
import {useNavigate} from "react-router-dom";
import html2canvas from "html2canvas";
import {BiDownload} from "react-icons/bi";
const SuccessTransferPage = () => {
    const location = useLocation();
    const ref = useRef(null)
    const [time,setTime] = useState(0)
    const navigate = useNavigate()


    useEffect(()=>{
        const timer = setTimeout(()=>{
            setTime(time + 1)
        },1000)
        if(time===30){
            navigate('/account')
        }
        return ()=>{

        }
    },[navigate, time])
    function exportPDF(title) {
        // const doc = new jsPDF()

        return html2canvas(ref.current)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf =  jsPDF();

                let width = pdf.internal.pageSize.getWidth()
                let imgSize = pdf.getImageProperties(imgData)
                pdf.setFillColor(255,255,255,1)
                pdf.rect(0,0,width,pdf.internal.pageSize.getHeight(),"F")
                let height = (imgSize.height * width)/imgSize.width
                pdf.addImage(imgData, 'PNG', 0, 0,width,height);
                if (title){
                    pdf.save(`llb-${title}.pdf`)
                }else{
                    pdf.save(`llb-file.pdf`)
                }
            })


    }

    return (
        <ClientDash title={'\t'}>

              <div className="center-all mgB2">
                  <p className="h7 greyText centerText">Page will redirect after 30s</p>
                  <h4 className="h4 greenText centerText">{time}</h4>
              </div>
                <div ref={ref} className="center-all width60 darkCard-alt mgT4">
                    <div className="darkCard-alt display_flex just-btw">
                        <h6 className="h5 ">Transaction Successful</h6>
                        <div className="">

                            <span className={'h7 '}> From {location.state?.sender}</span>
                            <p className={'h7 mgT1'}>{location.state?.senderAcc}</p>

                        </div>
                    </div>
                    {/*<hr className={'hr-dashed'}/>*/}
                     <div className="display_flex just-btw mgT4">
                             <h6 className="h6 greenText">LLB</h6>


                             <h6 className="h6  text_right"><Moment format={"MMMM DD YYYY"}/></h6>

                     </div>
                    <div className="mgT4">
                        <div className="display_flex just-btw">

                                <p className="h7 greyText">Receiver's Name</p>

                            <p className="h6 greyText">{location.state?.receiver} </p>
                        </div>
                        <div className="display_flex just-btw mgT2">

                                <p className="h7 greyText">Receiver's Account</p>

                            <p className="h6 greyText">{location.state?.receiverAcc} </p>
                        </div>
                        <hr className="hr-dashed"/>
                        <div className="display_flex just-btw mgT2">


                        </div>
                        <div className="display_flex just-btw mgT2">

                                <p className="h7 greyText">Total Amount</p>

                                <p className="h4 greyText">&#8364;{numberWithCommas(location.state?.amount)}</p>
                        </div>
                        <div className="mgT4 textCenter darkCard">
                            <h6 className="h6 greenText" onClick={()=>exportPDF('transfer--receipt')}>Download <BiDownload size={24}/></h6>
                        </div>
                    </div>

                </div>
        </ClientDash>
    );
};

export default SuccessTransferPage;