import React from 'react';
import AdminLayout from "../../../layout/AdminLayout";

const NewsManagement = () => {
    return (
        <AdminLayout>
            <h4 className="h4">News Management</h4>
            <div className="mgT4">
                <hr className="hr"/>
                <h5 className="h5 mgT2">Add News
                </h5>
                <form onSubmit={e=>e.preventDefault()}>
                    <div className="form-input-box">

                        <textarea style={{height:'10rem'}} placeholder={'Add news'} />
                    </div>
                    <button className="btn btn-blue mgT2">Add News</button>
                </form>
            </div>
        </AdminLayout>
    );
};

export default NewsManagement;