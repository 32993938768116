import React, {useState} from "react";
import Icons from "../assets/icon-sprite.svg";

export function MobileNavItem({title,items}) {
    const [show,setShow] = useState(false)
    return <div  onClick={()=>setShow(!show)} className={`mobile_nav-list-item ${show===true?'mobile_nav-list-top-active':''}`}>
        {/*{extra?extra:null}*/}
        <p className="mobile_nav-list-top h5 sans">{title}<span className={'icon'}>
           {show===true? <svg focusable="false" >
               <use  href={Icons + `#icon-arrow-up`}></use>
           </svg>: <svg focusable="false" >
               <use  href={Icons + `#icon-arrow-down`}></use>
           </svg>}
        </span></p>
        {show===true? <div className="mobile_nav-list-body">{items}</div>:null}

    </div>
}