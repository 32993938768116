import React,{useState} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import UserCard from "./userCard";
import Table from "../../../components/Table";
import axios from "../../../helpers/axiosInstance";
import {UserProvider} from "../../../hoc/userProvider";
import axiosInstance from "../../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Modal from "../../../components/Modal";
import AxiosInstance from "../../../helpers/axiosInstance";
import {handleValidate} from "../../../helpers/validateForm";
import Loader from "../../../components/loader";

const Users = () => {
    const [users,setUsers] = React.useState([]);
    const userInfo = React.useContext(UserProvider);
    const [loading,setLoading] = useState(false)
    const [refresh,setRefresh] = useState(false)
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [currentPage,setCurrentPage] = React.useState(0);
    const [showUser,setShowUser] = React.useState(false);
    // const history = useHistory();
    const getUsers = React.useCallback(()=>{
        setLoading(true)
        axios.get(`/sup/acc/get/all-users`,{
            headers:{
                authorization:userInfo.token
            }
        })
            .then(res=>{
                setLoading(false)
                setUsers(res.data.users);
            })
            .catch(err=>{
                setLoading(false)
            });

    },[setUsers,userInfo.token])

    function createUser() {
        const data = {
            email,
            password
        }
       if(handleValidate(email,"email") && handleValidate(password,"password")){
           AxiosInstance.post('/auth/reg',data)
               .then((res)=>{
                   setLoading(false)
                   toast.success('Success');
                   setRefresh(!refresh)
                   setTimeout(()=>{
                       setShowUser(false);

                   },1500)
               }).catch(err=>{

               setLoading(false)
               toast.error(err.response.data.msg)
           })
       }
       else{

           setLoading(false)
           toast.warning('Invalid Information, please fill correctly')
       }
    }

    React.useEffect(()=>{
        getUsers();
        return ()=>{
        }
    },[getUsers,refresh]);


  function deleteUser(data,func) {
        axios.delete(`/sup/acc/del/user/${data._id}`,{
            headers:{
                authorization: userInfo.token
            }
        })
            .then( async  res=>{
                const index  = users.findIndex(u=>{
                    return u._id === data._id;
                });
                console.log(index);
                let oldUsers = [...users];
                oldUsers.splice(index,1);
                setUsers(oldUsers);
                console.log(oldUsers)
                func(false);
                setCurrentPage(currentPage);
            })
            .catch(err=>{
                console.log(err)
            })
    }
  function suspendUser(data) {
        axiosInstance.post(`/admin/suspendUser/${data._id}`,{},{
            headers:{
                authorization: userInfo.token
            }
        })
            .then(res=>{
                //reset the suspend value of data
                const index  = users.findIndex(u=>{
                    return u._id===data._id;
                });
                console.log(index);
                let oldUsers = [...users];
                oldUsers[index].suspended = !data.suspended;
                setUsers(oldUsers);
                console.log(oldUsers)
                setCurrentPage(currentPage);
                // data.suspended = res.data.user_status
            })
            .catch(err=>{
                console.log(err);
            })
    }

  function changePage(e) {

        if(e<0){
            setCurrentPage(0);
        }
        setCurrentPage(e);
    }


    return (
        <AdminLayout title={'Users'}>
                {/*<h4 className="h4">Users</h4>*/}
              <div className="Gcenter-all">
                  <div className="mgT2 outlined-card">

                      <button className="btn btn-blue mgT4" onClick={()=>setShowUser(true)}>Create User</button>
                      {
                          showUser?<Modal closeModal={setShowUser}>
                              {
                                  loading?<Loader/>:<>
                                      <h6 className="h5 whiteText mgB2">Create User</h6>
                                      <label className="h7 greyText mgT4">User</label>
                                      <div className="form-input-box">
                                          <input  value={email}  onChange={(e)=>{
                                              setEmail(e.target.value)}} className={"form-input"} placeholder={'Email Address'}/>
                                      </div>
                                      <label className="h7 greyText">Password</label>
                                      <div className="form-input-box">
                                          <input  value={password} type={'password'}  onChange={(e)=>setPassword(e.target.value)} className={"form-input "} placeholder={'Password'}/>
                                      </div>
                                      <button className="btn btn-primary" onClick={createUser}>Create</button></>
                              }
                          </Modal>:null
                      }
                      <ToastContainer/>
                      <div className="mgT2">
                          <Table filters={true} type='dark-table' title={'users-list'} headers={['','#','Email','Reg Date']} body={
                              <>
                                  {

                                      users.length>0?

                                          users.map((user,i)=>{
                                              return <UserCard key={user._id} suspendUser={suspendUser} deleteUser={deleteUser} currentPage={currentPage}  data={user} id={i} list={users} setList={setUsers} />
                                          })
                                          : <tr>
                                              <td className='centerText' colSpan="7">
                                                  Sorry Nothing Found
                                              </td>

                                          </tr>
                                  }

                              </>
                          }/>
                      </div>
                  </div>
                  <div className="paginator">
                      <ul>
                          <li onClick={currentPage!==0?()=>changePage((currentPage - 1)):null} className={currentPage===0?'inactiveP':null}>Prev</li>
                          <li>&nbsp;</li>
                          <li onClick={users.length!==0 || users.length>3 ?()=>changePage((currentPage + 1)):null} className={users.length===0 || users.length<4?'inactiveP':null} >Next</li>
                      </ul>
                  </div>
              </div>

        </AdminLayout>
    );
};

export default Users;