import React from 'react';
import '../sass/components/tab.scss';

const AltTab = ({tabs,tabsBody,activeIndex,onChange}) => {

    return (
        <div className={'alt-tabs'}>
            <div className="center-all">
                <div className="alt-tabs__top">
                    {tabs.length>0?tabs.map((e,i)=><span onClick={()=>onChange(i)} key={i} className={activeIndex===i?"alt-tabs__top-item alt-tabs__top-item-active":"alt-tabs__top-item"}>{e}</span>):null}
                </div>
            </div>
            <div className="alt-tabs__body">
                {tabsBody?<div className={'center-all'}>{tabsBody}</div>:<><h6 className="h6 centerText"> Nothing Found</h6></>}
            </div>
        </div>
    );
};
export default AltTab;