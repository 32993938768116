import React from 'react';
import mapImage from '../assets/Maps_LLB_Vaduz_sw.jpg'
import smLogo from '../assets/logo-llb-small-circle-filled-bw-footer.svg'
import {FaFacebookF, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import Icons from '../assets/icon-sprite.svg'
const Footer = () => {
    return (
        <div className={'footer'}>
            <div className="footer-top ">
               <div className="center_nav grid">
                   <div className="grid-item3_4">
                       <ul className={'icon-list'}>
                           <li><FaFacebookF /></li>
                           <li><FaTwitter /></li>
                           <li><FaLinkedinIn /></li>
                           <li>
                               <svg focusable="false" className="icon ">
                                 <use  href={Icons + `#icon-xing`}></use>
                               </svg>
                           </li>
                           <li>
                               <svg focusable="false" className="icon ">
                                 <use  href={Icons + `#icon-kununu`}></use>
                               </svg>
                           </li>
                       </ul>
                   </div>


                            <div className={'to-top-alt'} onClick={()=>{    window.scrollTo(0,0)}}>
                                <span>To Top</span>
                                <svg focusable="false" className="icon mgL2">
                                    <use  href={Icons + `#icon-arrow-up-bold`}></use>
                                </svg>
                            </div>
               </div>
            </div>
            <div className="footer-center ">
   <div className="center_nav grid-">
       <div className="grid-item1_3">
           <h5 className="h5 sans whiteText">Quick Links</h5>
           <ul className={'footer-center-btns mgT2'}>
               <li>Private</li>
               <li>Business</li>
               <li>Institutional</li>
               <li>Private Banking</li>
           </ul>
       </div>
       <div className="grid-item1_3">
           <h5 className="h5 sans whiteText">Close To You</h5>
           <div style={{backgroundImage:`url(${mapImage}`, height:'15rem',
               backgroundPosition:'center', backgroundSize:"cover", backgroundRepeat:"no-repeat"}}
                className="mgT2">

           </div>
           <ul className={'footer-center-btns mgT1'}>
               <li>Find Location</li>
           </ul>
       </div>
       <div className="grid-item1_3">
           <h5 className="h5 sans whiteText">At Your Service</h5>
            <div className="display_flex mgT2">
                <div className="grid-item2_4">
                    <img style={{height:"5rem"}} src={smLogo} alt="logo"/>
                </div>
                <p className="h6 whiteText mgL2 ">Advisory Centre Eschen
                    Can be reached by phone, Monday to Friday, 8 a. m. – 5.30 p. m.</p>
            </div>
           <div className="greySmCard mgT4">
               +423 236 88 11
           </div>
           <div className="grid mgT1">
               <div className="grid-item2_4 greySmCard">
                   FeedBack
               </div>
               <div className="grid-item2_4 greySmCard">
                   Email
               </div>
           </div>
       </div>
   </div>
</div>
            <div className="footer-bottom">
                <div className="center_nav">
                    <ul>
                        <li>Sitemap




                        </li>
                        <li>  Imprint</li>
                        <li>  Legal notes</li>
                        <li> Privacy statement</li>
                        <li> Cookie Settings</li>
                    </ul>
                    <h6 className="h7"> © 2022 Liechtensteinische Landesbank</h6>
                </div>
            </div>
        </div>
    );
};

export default Footer;