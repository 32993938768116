import React, {useCallback, useContext, useEffect, useState} from 'react';
import ClientDash from "../../../layout/ClientLayout";
import Table from "../../../components/Table";
import tab2 from "../../../assets/key-insti-ebanking-3000x1280.jpg";

import {UserProvider} from "../../../hoc/userProvider";
import AxiosInstance from "../../../helpers/axiosInstance";
import {numberWithCommas, useRole} from "../../../helpers/misc";
import moment from "moment";
import axiosInstance from "../../../helpers/axiosInstance";
import {Link} from "react-router-dom";
import Modal from "../../../components/Modal";

const Dashboard = () => {
   const {status} =  useRole()
    const ctx = useContext(UserProvider);
    const user = JSON.parse(ctx.user) || localStorage.getItem('G7_li')
    const [transactions,setTransactions] = React.useState([]);
    const [showAccOfficer,setShowAccOfficer] = useState(false)
    // const [pg,setPg] = React.useState(1);
    // const [totPages,setTotPages] = React.useState(1);
    function formatDate(value) {
        return  moment(value).format('MMMM DD YYYY')
    }
    const getTransactions = React.useCallback(()=>{
        axiosInstance.get(`/trx/get/statement/${user._id}/`,{headers:{
                authorization: ctx.token
            }})
            .then((res)=>{
                setTransactions(res.data.data)
            })
            .catch(err=>{
                console.log(err);
            })
    },[setTransactions,ctx.token,user._id])

    const [wallet,setWallet] = useState({
        totalBalance:0
    })
    const getWallet = useCallback(()=>{
        AxiosInstance.get(`/acc/walletInfo/${user?.wallet._id}`,{})
            .then((res)=>{

                setWallet(res.data.data)
            })
            .catch(err=>{

            })
    },[setWallet,user.wallet._id])

    useEffect(()=>{
        getWallet()
    },[getWallet])
    React.useEffect(()=>{
        getTransactions();

        return ()=>{

        }
    },[getTransactions])
    return (
        <ClientDash title={'\n'}>

            {
                showAccOfficer?<Modal closeModal={setShowAccOfficer}>

                    <AccOfficer acc={user?.wallet.accNo}/>
                </Modal>:null
            }
            <h5 className="h5">Hi, {user?.firstName}</h5>
            <div className="grid-  mgT2">
                <div className="darkCard-alt grid-item2_4">
                    <div className="display_flex just-btw " style={{borderBottom:"4px solid var(--primary)",padding:'2rem 0'}}>
                        <h6 className="h7">Account</h6>
                        <h6 className="h7">{user?.wallet?.accNo}</h6>
                    </div>
                    <h5 className="h6 mgT2 ">{user?.firstName} {user?.lastName}</h5>
                    <div className="grid">
                        <div className="grid-item2_4">

                            <p className="mgT2 h8">AVAILABLE BALANCE</p>
                            <h4 className="h4 mgT2 ">&#8364;{numberWithCommas(wallet?.totalBalance)}</h4>
                        </div>
                        <div className="grid-item2_4 text_right">

                            <p className="mgT2 h8">STATUS</p>
                            <h4 className="h6 mgT2  toUpper">{status?status:"pending..."}</h4>
                        </div>
                    </div>

                    <div className="display_flex just-btw mgT2">
                        <span className="h7 " onClick={()=>setShowAccOfficer(true)}>Account Officer</span>
                    </div>
                </div>

                    <div className="adsCard grid-item2_4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab2})`}}>
                        <h5 className="h5 sans whiteText shadowText width60">Getting to the heart of financial and pension planning</h5>
                </div>
            </div>

            <div className="mgT4 grid">
               <div className="grid-item-f">
                   <h5 className="h5 greyText">Recent Transactions</h5>

                   <div className="mgT2">
                       <Table  title={'Transaction History'}type={'dark-table'} data={transactions} headers={['Title','Type','Amount','Date','Status']} body={<>
                           {
                               transactions.length>0?
                                   transactions.slice(0,4).map((e)=><tr key={e._id}>
                                       <td>{e.title}</td>
                                       <td>{e.trxType}</td>
                                       <td>&#8364;{e.amount}</td>
                                       <td>{formatDate(e?.createdAt)}</td>
                                       <td>{e?.status}</td>
                                   </tr>):null
                           }
                       </>}/>

                   </div>
                    <Link to={'/account/trx'} className="link h6 mgT2">View More</Link>
               </div>
            </div>
        </ClientDash>
    );
};

function AccOfficer({acc}) {
    return <div>
        <h5 className="h4 greenText">Kevin Buchel</h5>

        <div className="mgT2">
           <p className="h8 greyText">Account</p>
            <h6 className="h6">{acc?acc:0}</h6>
           <p className="h8 greyText mgT2">Phone Number</p>
            <h6 className="h6">+423-755-578-720</h6>
           <p className="h8 greyText mgT2">Email</p>
            <h6 className="h6">Kevin.buchel@liechensteinishe.com</h6>
        </div>
    </div>
}

export default Dashboard;