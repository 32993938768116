import React, {useCallback, useEffect, useState} from 'react';
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import car1 from '../assets/leadimage-image-kindervelo-3000x1280-3.png'
import car2 from '../assets/key-insti-ebanking-3000x1280.jpg';
import {AnimatePresence, motion} from "framer-motion";

const Carousel = ({slide1}) => {
    const [images] = useState([slide1?slide1:car1,car2]);
    const [index,setIndex] = useState(0);
    const changeImage = useCallback(()=>{

        let index_ = (index + 1)%(images.length ) ;
        setIndex(index_)
    },[setIndex,index,images])
    useEffect(()=>{
        let timer = setInterval(()=>{
            changeImage();
        },150000);

        return ()=>clearInterval(timer)
    },[changeImage])
    return (
        <div className={'carousel'} >
           <AnimatePresence>

               {
                   <motion.div
                       key={images[index]}
                       style={{backgroundImage:`linear-gradient(#0002,#0002),url(${images[index]})`}}
                       initial={{ x: 300 }}
                       transition={{duration:.35}}
                       animate={{ x: 0, opacity: 1 }}
                       exit={{ x: -300 }}
                        className={'carousel-img'}
                   />
               }
               {
                   index===0? <div className="carousel-text display_flex just-btw align-center">
                       <h4 className="h4 whiteText textShadow width80 sans">{slide1?"Tailored Products for your company":"Sustainable Investments. Built on experience"}</h4>
                       <button className="btn-red btn nowhitespace h5 sans">More Details</button>
                   </div>:
                       <div className="carousel-text display_flex just-btw align-center">
                           <h4 className="h4 whiteText textShadow width80 sans">Transact Your Bank Business Online and Anywhere</h4>
                           <button className="btn-red btn nowhitespace h5 sans">More Details</button>
                       </div>
               }

           </AnimatePresence>

            <div className="carousel_dots">
                {
                    images.map((e,i)=><span key={i} className={index===i?"carousel_dots-item-active":"carousel_dots-item"}>&nbsp;</span>)
                }
            </div>
            <div className="carousel_btns">
                <div className="carousel_btns-prev" onClick={()=>{
                    let index_ = (index + 1)%(images.length ) ;
                    setIndex(index_)
                }}>
                    <BiChevronLeft size={48} color={'#f7f7f7'}/>
                </div>
                <div className="carousel_btns-next">
                    <BiChevronRight size={48} color={'#f7f7f7'} onClick={()=>{
                        let index_ = (index + 1)%(images.length ) ;
                        setIndex(index_)
                    }} />
                </div>
            </div>
        </div>
    );
};

export default Carousel;