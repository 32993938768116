import {Routes, Route, Link, useNavigate} from 'react-router-dom';
import React,{useState,useCallback} from "react";
import Home from './pages/home/home'
import Dashboard from "./pages/client/dashboard/dashboard";
import './sass/main.scss'
import {UserProvider} from './hoc/userProvider'
import Users from "./pages/admin/users/users";
import TransfersPage from "./pages/client/TransfersPage";
import Settings from "./pages/client/settings";
import Transactions from "./pages/client/transactions";
import Invoices from "./pages/client/invoices";
import Wallet from "./pages/client/wallet";
import SuccessTransferPage from "./pages/client/successTransferPage";
import Corporate from "./pages/home/Corporate";
import TheLLB from "./pages/home/theLLB";
import EBanking from "./pages/home/EBanking";
import Institutional from "./pages/home/Institutional";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import {AnimatePresence} from "framer-motion";
import Complaints from "./pages/client/Complaints";
import PrivateRoute from "./hoc/privateRoute";
import AdminHome from "./pages/admin/adminHome";
import AddArticle from "./pages/admin/blog/addArticle";
import AdminSettings from "./pages/admin/AdminSettings";
import SWIFTCODES from "./pages/admin/SWIFTCODES";
import AdminStatement from "./pages/admin/users/AdmiinStatement";
import NewsManagement from "./pages/admin/news/newsMangement";
import Messages from "./pages/admin/message/messages";
import AllTransactions from "./pages/admin/AllTransactions";
import PinGenerate from "./pages/admin/pin/pinGenerate";

import 'react-toastify/dist/ReactToastify.css';
import TrxFailurePage from "./pages/client/failurePage";
import ComplaintsPage from "./pages/admin/complaints/complaintsPage";
import ResetPassword from "./pages/auth/reset";
import ResetPasswordRe from "./pages/auth/reset_re";
function App() {
    const [user,setUser] = useState(localStorage.getItem('G7_li'));
    const [token,setToken] = useState(localStorage.getItem('G7_TK'));
    const navigate = useNavigate();
    const logOut = useCallback (()=> {
        localStorage.clear();
        navigate('/login',{replace:true});
    },[navigate])
    function login(data,token) {
        setUser(JSON.stringify(data));
        setToken(token);
        localStorage.setItem('G7_TK',token)
        localStorage.setItem('G7_li',JSON.stringify(data));
        // //navigate to dashboard
        console.log(data)
        if(data.role===0){
            navigate('/account',{replace:true});
        }else{
            navigate('/admin',{replace:true});
        }
    }
  return (
      <AnimatePresence>
        <UserProvider.Provider value={{token,user,logOut,login}}>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/corporate'} element={<Corporate/>}/>
                <Route path={'/llb'} element={<TheLLB/>}/>
                <Route path={'/e-banking'} element={<EBanking/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/register'} element={<Register/>}/>

                <Route exact path='/resetPassword' element={<ResetPassword/>}/>
                <Route exact path='/reset/verify/:id' element={<ResetPasswordRe/>}/>

                <Route path={'/institutional'} element={<Institutional/>}/>
                <Route path={'/account'} element={<Dashboard/>}/>
                <Route path={'/account/transfers'} element={<TransfersPage/>}/>
                <Route path={'/account/transfers-success'} element={<SuccessTransferPage/>}/>
                <Route path={'/account/transfer-failed'} element={<TrxFailurePage/>}/>
                <Route path={'/account/wallet'} element={<Wallet/>}/>
                <Route path={'/account/invoices'} element={<Invoices/>}/>
                <Route path={'/account/complaints'} element={<Complaints/>}/>
                <Route path={'/account/settings'} element={<Settings/>}/>
                <Route path={'/account/trx'} element={<Transactions/>}/>
                {/*ADMIN AREA*/}

                {/*ADMIN ROUTES*/}
                <Route path={'/admin'} element={<PrivateRoute><AdminHome/></PrivateRoute>}/>
                <Route path={'/admin/users'} element={<PrivateRoute><Users/></PrivateRoute>}/>
                <Route path={'/admin/complaints'} element={<PrivateRoute><ComplaintsPage/></PrivateRoute>}/>
                <Route path={'/admin/blog'} element={<PrivateRoute><AddArticle/></PrivateRoute>}/>
                <Route path={'/admin/settings'} element={<PrivateRoute><AdminSettings/></PrivateRoute>}/>
                <Route path={'/admin/swift'} element={<PrivateRoute><SWIFTCODES/></PrivateRoute>}/>
                <Route path={'/admin/pin'} element={<PrivateRoute><PinGenerate/></PrivateRoute>}/>
                <Route exact path={'/admin/acc/statement/:id'} element={<PrivateRoute><AdminStatement/></PrivateRoute>}/>
                <Route path={'/admin/news-management'} element={<PrivateRoute><NewsManagement/></PrivateRoute>}/>
                <Route path={'/admin/reset-password'} element={<PrivateRoute><AdminSettings/></PrivateRoute>}/>
                <Route path={'/admin/messages'} element={<PrivateRoute><Messages/></PrivateRoute>}/>
                <Route path={'/admin/allTransactions'} element={<PrivateRoute><AllTransactions/></PrivateRoute>}/>



                <Route path={'/*'} element={<>
                    <h5 className={'h2'}>Error Page not found </h5>
                    <p className="h6">
                        Go back to  <Link className={'link'} to={'/'}>Home Page </Link>
                    </p>

                </>}/>
            </Routes>
        </UserProvider.Provider>
      </AnimatePresence>
  );
}

export default App;
