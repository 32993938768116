// import React from 'react';

export default class IdleTimer {
    constructor({timeout,onTimeOut}) {
        this.timeout = timeout;
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
        this.onTimeOut = onTimeOut;
    }
    updateExpiredTime(){
        localStorage.setItem('__expiredTime', (Date.now() + this.timeout * 1000).toString());

    }
    tracker(){
        window.addEventListener('mousemove',this.eventHandler);
        window.addEventListener('scroll',this.eventHandler);
        window.addEventListener('keydown',this.eventHandler)
    }
    startInterval(){
        this.updateExpiredTime();
        this.interval = setInterval(()=>{
            const expiredTime = parseInt(localStorage.getItem('__expiredTime') || 0,10);
            if (expiredTime<Date.now()){
                if (this.onTimeOut){
                    this.onTimeOut();
                }
            }
        },1000)
    }


}