import React from 'react';
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import {Link} from "react-router-dom";

const SlideCards = () => {
    function generateRandom(min = 0, max = 128) {

        // find diff
        let difference = max - min;

        // generate random number
        let rand = Math.random();

        // multiply with difference
        rand = Math.floor( rand * difference);

        // add with min value
        rand = rand + min;

        return rand;
    }
    return (
        <div className={'slideCards'}>

            <div className="slideCards-area  center-all">
                <SlideCard/>
                <SlideCard/>
                <SlideCard/>
                <SlideCard/>
                <SlideCard/>
                <SlideCard/>
            </div>
            <div className="carousel_btns">
                <div className="carousel_btns-prev">
                    <BiChevronLeft size={48} />
                </div>
                <div className="carousel_btns-next">
                    <BiChevronRight size={48}  />
                </div>
            </div>

            <div className=" mgV2 centerText">
                <Link to={'/'} className={'btn btn-grey h4'}>To the media communiqués</Link>
            </div>
        </div>
    );
};

function SlideCard() {
    return <div className={'slideCard'}>
                <h6 className="h6 whiteText shadowText">19.05.22</h6>
                <h6 className="h5 whiteText mgT1 sans shadowText">LLB Successfully complete public tender offer</h6>
          </div>
}
export default SlideCards;