import React, {useState} from 'react';
import Navigation from "../../components/Navigation";
import Carousel from "../../components/carousel";
import Footer from "../../components/Footer";
import AltTab from "../../components/Alttab";
import SlideCards from "./SlideCards";
import tab1Lg from '../../assets/tab1lg.jpg'
import tab1Lg2 from '../../assets/tab1lg2.jpg'
import tab1Lg3 from '../../assets/tab1lg3.jpg'
import tab2Lg2 from '../../assets/tab2lg2.jpg'
import foreign from '../../assets/foreign_risk.png';
import finance from '../../assets/financ.png'
import keySol from '../../assets/key-xpsolutions-3000x1280.jpg'
import keySol2 from '../../assets/lead-xpsolutions1-3000x1280.jpg'
import tailored from '../../assets/tailred.jpg'
import tv from '../../assets/tv-.svg'
import tab2 from "../../assets/tab2.jpg";
import tab4 from "../../assets/tab4.jpg";
import tab3 from "../../assets/tab3.jpg";
const EBanking = () => {
    const [index,setIndex] = useState(0)
    const [tabs] = useState([<Tab1/>,<Tab2/>,<Tab3/>])
    return (
        <div>
            <Navigation
                bottomTabsContent={[<DropDown1/>,<DropDown2/>,<DropDown3/>,<DropDown4/>,<DropDown5/>]}
                bottomTabs={["Pay & Save"," Investing","Financing","Financial & Pension Planning","Private Banking"]}/>
            <Carousel/>
            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">The best for your assets</h5>
                <p className="h5 mgT2">As the bank with the richest tradition in Liechtenstein and as an international corporate group, we have offered security and stability to our clients for 160 years. Clients appreciate our tradition and our innovative strength. Take advantage of our outstanding expertise and services – we are here for you.</p>
            </div>
            <div className="">
                <>
                    <AltTab tabs={['Private Clients','Corporate Clients','Institutional Client']} activeIndex={index} tabsBody={tabs[index]} onChange={setIndex}/>
                </>
            </div>
            <div className="mgV8 center-all">
                <div className="grid-" style={{backgroundColor:"#f2f1f0"}}>
                    <div className="grid-item1_4" style={{backgroundImage:`url(${tv})`, backgroundPosition:"center",
                        backgroundRepeat:"no-repeat",
                        backgroundSize:"cover", padding:"4rem",}}>
                        {/*<img src={tv} alt="tv" className={'fluidImg'}/>*/}
                    </div>
                    <div className="grid-item3_4" style={{padding:"2rem"}}>
                        <h5 className="h5 sans dark-green">Your entry to Online Banking. Register now with your user data and launch Online Banking.</h5>
                        <button className="btn btn-red mgT4 h5 sans">Register</button>
                    </div>
                </div>
            </div>

            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">Current media communiqués</h5>
                <p className="h5 mgT2">We rely on transparency and up-to-date information so that our clients, investors, and partners always have the best possible information at their disposal. Access our latest news & communiqués and stay up to date on what moves the LLB Group.</p>
            </div>

            <div className="center-all">
                <SlideCards/>
            </div>
            <Footer/>
        </div>
    );
};



function DropDown5() {
    return <div>
        <h5 className="h6 greenText">The partner at your side</h5>
        <p className="h6 mgT1 greyText">
            We offer security, excellent consulting and a long lasting partnership. Get to know us – for your success.


        </p>
        <p className="mgT1 h6 blueText">Learn More</p>
        <div className="grid- mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">Caring about your values</h5>
                <ul className={'list'}>
                    <li>Your needs


                    </li>
                    <li> Personal contacts</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Assets</h5>
                <ul className={'list'}>
                    <li> Excellent advice



                    </li>
                    <li>Solid protection</li>
                    <li>  Taxes</li>
                    <li> Quellensteuer</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">LLB Invest</h5>
                <ul className={'list'}>
                    <li> Advisory models
                    </li>
                    <li> Investment advice</li>
                    <li>  Asset management</li>
                    <li>
                        Advisory & investment philosophy</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Offices</h5>
                <ul className={'list'}>
                    <li> Central & Eastern Europe

                    </li>
                    <li>  Middle East</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab2})`,height:"18rem"}}>
            <h5 className="h5 sans whiteText shadowText width60">Getting to the heart of financial and pension planning</h5>
            <button className="btn-outlined btn-outlined-white mgT2 shadowText">Find Out More</button>
        </div>
    </div>
}
function DropDown4() {
    return <div>
        <h5 className="h6 greenText">360°-Analysis</h5>
        <p className="h6 mgT1 greyText">Financial and pension planning – for a future without worry.

        </p>
        <p className="mgT1 h6 blueText">LLB Compass</p>
        <div className="grid- mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">Life Events</h5>
                <ul className={'list'}>
                    <li>Your financial and pension needs

                    </li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Phases of Life</h5>
                <ul className={'list'}>
                    <li>Your situation</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Taxes</h5>
                <ul className={'list'}>
                    <li> Tax planning
                    </li>
                    <li>Tax reporting</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab4})`,height:"20rem"}}>
            <h5 className="h5 sans whiteText shadowText width60">Getting to the heart of financial and pension planning</h5>
            <button className="btn-outlined btn-outlined-white mgT2 shadowText">Find Out More</button>
        </div>
    </div>
}
function DropDown3() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Residential Buildings</h5>
                <ul className={'list'}>
                    <li>Mortgages</li>

                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Loans</h5>
                <ul className={'list'}>
                    <li>Lombard Loans</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Caring About Your Values</h5>
                <ul className={'list'}>
                    <li>Contact Partners</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab3})`}}>
            <h5 className="h5 sans whiteText shadowText">Liechtenstein is building – with solid advice</h5>
            <button className="btn-outlined btn-outlined-white mgT2 shadowText">Find Out More</button>
        </div>
    </div>
}
function DropDown2() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">LLB Invest</h5>
                <ul className={'list'}>
                    <li>Advisory Models</li>
                    <li>Investment Advice</li>
                    <li>Asset management</li>
                    <li>Advisory & Investment</li>
                    <li>Philosophy</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Funds</h5>
                <ul className={'list'}>
                    <li>LLB funds

                    </li>
                    <li> Fund Savings Plan</li>
                    <li>Investment Plan</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Direct Investment</h5>
                <ul className={'list'}>
                    <li>Money market
                    </li>
                    <li>Equities</li>
                    <li>   Bonds</li>
                    <li> Medium-term notes</li>
                    <li> Foreign exchange transactions</li>
                    <li>  Foreign exchange and precious metal</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Further Products</h5>
                <ul className={'list'}>
                    <li>Structured products

                    </li>
                    <li> Derivative transactions</li>
                    <li>Securities Lending Borrowing</li>
                </ul>
            </div>
        </div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Additional Services</h5>
                <ul className={'list'}>
                    <li>Safe deposit box
                    </li>
                    <li> Tax reporting</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Current Market Data</h5>
                <ul className={'list'}>
                    <li>LLB Quotes</li>
                </ul>
            </div>

        </div>
        <div className="navigation_bottom_ads" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab2})`}}>
            <h5 className="h5 sans whiteText shadowText">Investment solutions tailored to you</h5>
            <button className="btn-outlined btn-outlined-white mgT2">Start here</button>
        </div>
    </div>
}
function DropDown1() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">LLB Combi</h5>
                <ul className={'list'}>
                    <li>Combinations</li>
                    <li>Basic Combi</li>
                    <li>Operational Modules</li>
                    <li>Discount</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Payment Methods</h5>
                <ul className={'list'}>
                    <li>Private Account</li>
                    <li>Current Account</li>
                    <li>Rent Account</li>
                    <li>"Youli" youth Account</li>
                    <li>"You Study" Student Account</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Savings Account</h5>
                <ul className={'list'}>
                    <li>Savings Account</li>
                    <li>Gifts Savings Account</li>
                    <li>Blocked Pensions Savings Account</li>
                    <li>Rental Deposit Savings Account</li>
                    <li>"You Save" Youth Savings Account</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Cards</h5>
                <ul className={'list'}>
                    <li>Visa Debit Card</li>
                    <li>Credit Card</li>
                    <li>Apple Pay</li>
                    <li>Travel Plan</li>
                </ul>
            </div>
        </div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Payment Services</h5>
                <ul className={'list'}>
                    <li>QR Bill</li>
                    <li>Incoming Payments</li>
                    <li>Outgoing Payments</li>
                    <li>SEPA, IBAN, etc.</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Digital Solutions</h5>
                <ul className={'list'}>
                    <li>LLB online Banking</li>
                    <li>LLB mobile Banking</li>
                    <li>LiPay</li>
                    <li>EBICS</li>
                    <li>ebill</li>
                </ul>
            </div>

        </div>
    </div>
}

function Tab3() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${keySol})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Give your clients a decisive edge--LLB Xpert Solutions</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${keySol2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">More time for your clients - LLB Experts Solutions</h4>
            </div>
            <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
        </div>
    </div>
}
function Tab2() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${tailored})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Tailored banking relationships</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab2Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Digital Offerings</h4>
            </div>
            <div className="grid">
                <div className="mgT2 grid-item2_4" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${finance})`,borderRadius:".25rem",backgroundSize:'cover',
                    backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                    <h4 className="h5 sans shadowText whiteText">Financing</h4>
                </div>
                <div className="grid-item2_4">
                    <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${foreign})`,borderRadius:".25rem",backgroundSize:'cover',
                        backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                        <h4 className="h5 sans shadowText whiteText">Foreign Current Risks</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
function Tab1() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Your desired banking relationship--LLB invest</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
            <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg3})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
        </div>
    </div>
}
export default EBanking;