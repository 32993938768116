import React, {useState} from 'react';
import Navigation from "../../components/Navigation";
import Carousel from "../../components/carousel";
import Footer from "../../components/Footer";
import AltTab from "../../components/Alttab";
import SlideCards from "./SlideCards";
import tab1Lg from '../../assets/tab1lg.jpg'
import tab1Lg2 from '../../assets/tab1lg2.jpg'
import tab1Lg3 from '../../assets/tab1lg3.jpg'
import tab2Lg2 from '../../assets/tab2lg2.jpg'
import foreign from '../../assets/foreign_risk.png';
import finance from '../../assets/financ.png'
import keySol from '../../assets/key-xpsolutions-3000x1280.jpg'
import keySol2 from '../../assets/lead-xpsolutions1-3000x1280.jpg'
import tailored from '../../assets/tailred.jpg'
import tv from '../../assets/tv-.svg'
import tab from "../../assets/senses.png";
import tab2 from "../../assets/thllb.png";
import tab4 from "../../assets/career.png";
import tab5 from "../../assets/investtors.png";
const TheLLB = () => {
    return (
        <div>
            <Navigation
                bottomTabsContent={[<DropDown1/>,<DropDown2/>,<DropDown3/>,<DropDown4/>,<DropDown5/>,<DropDown6/>]}
                bottomTabs={["About Us"," Engagement","Sustainability","Career","Investors","Media"]}/>
            <Carousel/>
            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">The best for your assets</h5>
                <p className="h5 mgT2">As the bank with the richest tradition in Liechtenstein and as an international corporate group, we have offered security and stability to our clients for 160 years. Clients appreciate our tradition and our innovative strength. Take advantage of our outstanding expertise and services – we are here for you.</p>
            </div>

            <div className="mgV8 center-all">
                <div className="grid-" style={{backgroundColor:"#f2f1f0"}}>
                    <div className="grid-item1_4" style={{backgroundImage:`url(${tv})`, backgroundPosition:"center",
                        backgroundRepeat:"no-repeat",
                        backgroundSize:"cover", padding:"4rem",}}>
                    </div>
                    <div className="grid-item3_4" style={{padding:"2rem"}}>
                        <h5 className="h5 sans dark-green">Your entry to Online Banking. Register now with your user data and launch Online Banking.</h5>
                        <button className="btn btn-red mgT4 h5 sans">Register</button>
                    </div>
                </div>
            </div>

            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">Current media communiqués</h5>
                <p className="h5 mgT2">We rely on transparency and up-to-date information so that our clients, investors, and partners always have the best possible information at their disposal. Access our latest news & communiqués and stay up to date on what moves the LLB Group.</p>
            </div>

            <div className="center-all">
                <SlideCards/>
            </div>
            <Footer/>
        </div>
    );
};



function DropDown6() {
    return <div>

        <div className="grid- mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">Media communiqués</h5>
                <ul className={'list'}>
                    <li>Media communiqués
                    </li>
                    <li> Archive 2012-2016</li>

                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Media offices</h5>
                <ul className={'list'}>
                    <li> Contact persons</li>
                </ul>
            </div>
        </div>

    </div>
}
function DropDown5() {
    return <div>

        <div className="grid- mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">Overview</h5>
                <ul className={'list'}>
                    <li>Executive summary


                    </li>
                    <li> Strategy</li>
                    <li> Contact persons </li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Dates</h5>
                <ul className={'list'}>
                    <li>   Financial calendar



                    </li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Facts & Figures</h5>
                <ul className={'list'}>
                    <li>  Overview


                    </li>
                    <li>  Reports</li>
                    <li> Rating</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">LLB Share</h5>
                <ul className={'list'}>
                    <li>Details</li>
                    <li>Share repurchase </li>
                </ul>
            </div>
        </div>
        <div className="grid mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">General Meeting</h5>
                <ul className={'list'}>
                    <li>Overview</li>
                    <li>Information for shareholders</li>
                    <li>Resolution minutes</li>
                    <li>Statutes</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0008,#0005),url(${tab5})`,height:"18rem"}}>
            <h5 className="h5 sans whiteText shadowText width60">Current annual and interim financial reports at a glance.</h5>
            <button className="btn-outlined btn-outlined-white mgT2 shadowText">Read More</button>
        </div>
    </div>
}
function DropDown4() {
    return <div>
        <div className="grid- mgT4">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">What we Stand for</h5>
                <ul className={'list'}>
                    <li>The LLB as an employer  </li>
                    <li> Vision and guiding principles</li>
                    <li>  Employer awards</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">What we offer</h5>
                <ul className={'list'}>
                    <li>Our offer </li>
                    <li>Compensation</li>
                    <li>Continuing education</li>
                    <li>Open positions</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Experienced Professionals</h5>
                <ul className={'list'}>
                    <li>Your challenge</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Graduates</h5>
                <ul className={'list'}>
                    <li>Your career entry</li>
                    <li>Bachelor Graduate Programme</li>
                    <li>Trainee programme for master's graduates</li>
                    <li>Work-study programme for master's students</li>
                </ul>
            </div>
        </div>
        <div className="grid- mgT2">

            <div className="grid-item1_4">
                <h5 className="h6 greenText">Students</h5>
                <ul className={'list'}>
                    <li>Your career entry</li>
                    <li>Commercial apprenticeship</li>
                    <li>IT apprenticeship</li>
                    <li>A look at our apprenticeship programmes</li>
                    <li>Information days</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Contact</h5>
                <ul className={'list'}>
                    <li>Your contact persons</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab4})`,height:"17rem", backgroundRepeat:"no-repeat"}}>
            <h5 className="h5 sans whiteText shadowText width60">Getting to the heart of financial and pension planning</h5>
            <button className="btn-outlined btn-outlined-white mgT2 shadowText">More</button>
        </div>
    </div>
}
function DropDown3() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Responsibility</h5>
                <ul className={'list'}>
                    <li>Future Foundation</li>
                    <li>
                        Sustainability</li>

                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Regulation</h5>
                <ul className={'list'}>
                    <li>
                        Disclosure</li>
                </ul>
            </div>
        </div>
    </div>
}
function DropDown2() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Sponsoring</h5>
                <ul className={'list'}>
                    <li>Overview</li>
                    <li>
                        Society</li>
                    <li>
                        Cultural</li>
                    <li>
                        Sport</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Events</h5>
                <ul className={'list'}>
                    <li>LLB Summer in the Courtyard</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab2})`}}>
            <h5 className="h5 sans whiteText shadowText">Investment solutions tailored to you</h5>
            <button className="btn-outlined btn-outlined-white mgT2">More</button>
        </div>
    </div>
}
function DropDown1() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Our Values</h5>
                <ul className={'list'}>
                    <li>What makes us who we are</li>
                    <li>
                        Image</li>
                    <li>
                        History</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Our Structures</h5>
                <ul className={'list'}>
                    <li>
                        Organisation</li>
                    <li>
                        Governance</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Business Locations</h5>
                <ul className={'list'}>
                    <li>Liechtenstein</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Bank Offices</h5>
                <ul className={'list'}>
                    <li>
                        Overview</li>
                    <li>
                        Vaduz</li>
                    <li>
                        Balzers</li>
                    <li>
                        Eschen</li>
                </ul>
            </div>
        </div>

        <div className="navigation_bottom_ads" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab})`}}>
            <h5 className="h5 sans whiteText shadowText">Experience banking with all your senses.</h5>
            <button className="btn-outlined btn-outlined-white mgT2">More</button>
        </div>
    </div>
}

export default TheLLB;