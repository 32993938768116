import React from 'react';
import '../sass/components/tab.scss';

const Tab = ({tabs,tabsBody,activeIndex,onChange}) => {

    return (
        <div className={'tabs'}>
            <div className="tabs__top">
                {tabs.length>0?tabs.map((e,i)=><span onClick={()=>onChange(i)} key={i} className={activeIndex===i?"tabs__top-item tabs__top-item-active":"tabs__top-item"}>{e}</span>):null}
            </div>
            <div className="tabs__body">
                {tabsBody?<>{tabsBody}</>:<><h6 className="h6 centerText"> Nothing Found</h6></>}
            </div>
        </div>
    );
};
export default Tab;