import React, {useState} from 'react';
import ClientDash from "../../layout/ClientLayout";
import InlineLoader from "../../hoc/Inlin-loader";
import {useNavigate} from "react-router-dom";

const TransfersPage = () => {
    const  [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    function makeTransfer(e){
        e.preventDefault();
        setLoading(true);
        setTimeout(()=>{
            setLoading(false)
            navigate('/account/transfers-success')
        },3500)
    }
    return (
        <ClientDash title={'Wire Transfers'}>
          <div className="center-all width60">
              {
                  loading?<InlineLoader/>:null
              }
          </div>
            <div className="mgT4 center-all width60">
               <div className="">
                   <form className={'darkCard-alt mgT2'} onSubmit={makeTransfer}>

                       <h5 className="h6 greyText mgB2">Make wire Transfers easily</h5>
                       <div className="form-input-box">
                           <input type="text" className="form-input" placeholder={"Recipient's Name"}/>
                       </div>
                       <div className="form-input-box">
                           <textarea className="form-textArea" placeholder={"address"}/>
                       </div>
                       <div className="form-input-box">
                           <input type="text" className="form-input" placeholder={"bank account Number"}/>
                       </div>
                      <div className="form-group">
                          <div className="form-input-box">
                              <input type="text" className="form-input" placeholder={"ABA"}/>
                          </div>
                          <div className="form-input-box">
                              <input type="text" className="form-input" placeholder={"Sort Code"}/>
                          </div>
                      </div>

                       <div className="form-input-box">
                           <input type="text" className="form-input" placeholder={"Amount"}/>
                       </div>
                       <button className="btn btn-primary mgT2">Proceed</button>
                   </form>
               </div>
            </div>
        </ClientDash>
    );
};

export default TransfersPage;