import React, {useRef} from 'react';
import {BiSearch} from "react-icons/bi";
import { writeFileXLSX,utils } from "xlsx";
import '../sass/components/table.scss'
import jsPDF from "jspdf";
import  "jspdf-autotable";
// import Logo from '../assets/pdf_logo.png'
const Table = ({type,headers,body,filters,data,title,searchQuery,searchText,onTextChange}) => {
    const tableRef = useRef(null);
    function downloadExcel() {
        let table_elt = tableRef.current;
        let workbook = utils.table_to_book(table_elt);
       if (title){
           writeFileXLSX(workbook, `${title}.xlsx`);
       }
        writeFileXLSX(workbook, `llb.xlsx`);
    }
   const base64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABJlBMVEUAAAAAAABVAABAQEAzMzMrKyszMzMrKysoKCgmJiYxJCQvJiYuLiQsLCwrKysrKyYrKyYpKSktKCgqKicsKSkrKSkrKygqKicrKykrKygsKigsKigrKigrKScrKigrKSgrKycqKiksKigrKigrKycrKigrKSgrKygsKicrKSgrKygsKicrKiksKigrKSgrKygrKigrKigrKigrKicrKigrKygrKygrKigrKigrKigrKigrKigrKigrKigrKigyMjA1NDI8PDo/PjxAPz1IR0ZJSEZQT01SUVBUU1FVVFJzc3F3dnV/fn2BgH+Hh4aLiomLi4qamZi5uLi5ubjIx8bIx8fW1tbX19fa2trc3Nzg39/n5+fs7Ozz8/P39/f4+Pj9/Pz///+tE1HeAAAAPnRSTlMAAgMEBQYKEhMUFRscHR41Njg5VVdYWlt3eHqGh4iTlJWXmJmbq62ur7O0tba70dLU1eHi4+Tq6+zt7vj5+kGlJc4AAAABYktHRGGysEyGAAABgUlEQVQ4y43VeV+CMBgH8KGmlpZ3KWKJV4eVlpoiuLK7JMtuu/f+30QYAWOM4/cXg++H8eyzPQCAx5dka3v9waDfrLEJBthlsdCBWNpclMoWyhIkIvHzVrcsQEp6GYIFKtAmpQDugtvQNvUg9j4HB+HOnA4r0DG85lagS9KqC3fdoKCuUlm/cYzQM1UWZy5qrPMJQi9UKC0psKCNhsNzhF6Heg4xyQHAtLUBInKHwY4PJKEXCOOA1a+n0zeEvqdqPgiYA+vYCCvmloBV0CTh55eSaxI2gEDCn9n3jUjYBZI3KHqHnqe2FCPfKDmzFkMsz7us5h6hB1k+xZaHNUNzRvqjLEh4gzHAtAx4NNHziNDTZHKhPdlX2gZH3YFEMXnzxrWFYmS2xXkavByPr4zR6t+ZCbkert5/C8q4wZR2sEvObs3onxtObsuPNam6vdsMmtoebzuvn+iQaWrtBylryw0XRZKJqyFqF49wLZy18hHb/wITz1UbgiQJu9VszPz7+AXn0kkQQcIXOQAAAABJRU5ErkJggg=="

    async function exportPDF() {
        const doc = new jsPDF()

        doc.autoTable({ html: '#table--', didDrawPage: function (data) {

                // Header
                doc.setFontSize(20);
                doc.setTextColor(40);
                doc.text("LLB Report", data.settings.margin.left + 15, 22);
                if (base64) {
                    doc.addImage(base64, 'JPEG', data.settings.margin.left, 15, 10, 10);
                }
                // Footer
            },  margin: {top: 30}})
        // doc.save('table.pdf')
        if (title){
            doc.save(`llb-${title}.pdf`)
        }else{
            doc.save(`llb-file.pdf`)
        }

    }
    return (
        <div className='table-holder'>
            {
               filters? <div className={'table-filter'}>
                    <div className="table-filter-list">
                         <span className="table-filter-list-item">
                        Copy
                    </span>
                        <span onClick={downloadExcel} className="table-filter-list-item">
                        Excel
                    </span>
                        <span onClick={exportPDF} className="table-filter-list-item">
                        PDF
                    </span>
                    </div>
                    <form onSubmit={searchQuery} className="table-filter-search">
                        <input value={searchText} onChange={(e)=>onTextChange(e.target.value)} type="text" placeholder={'Search'}/>
                      <button className="" style={{border:'none',backgroundColor:'inherit'}}>
                          <BiSearch size={24}/>
                      </button>
                    </form>
                </div>:null
            }
             <table className={type} ref={tableRef} id={'table--'}>
                 <thead>
                    <tr>{
                        headers.map((e,i)=>{
                            return (
                                <th key={i} >
                                    {e}
                                </th>
                            )
                        })
                    }</tr>
                 </thead>
                 <tbody>
                 {body}
                 </tbody>
             </table>
        </div>
    );
};

export default Table;