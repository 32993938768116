import React,{useRef} from 'react';
import ClientDash from "../../layout/ClientLayout";

import {useLocation} from "react-router-dom";
import {AiFillCloseCircle} from "react-icons/ai";
import {numberWithCommas} from "../../helpers/misc";

const TrxFailurePage = () => {

    const location = useLocation()
    return (
        <ClientDash title={'\t'}>
                <div  className="center-all width60 darkCard-alt mgT4">
                    <div className="darkCard-alt centerText">
                        <h6 className="h5 redText centerText">Transaction Failed</h6>
                        <span className={'redText centerText mgT4'}> <AiFillCloseCircle size={48}/> </span>
                    </div>
                    {/*<hr className={'hr-dashed'}/>*/}
                    <div className="mgT2 centerText">
                        <p className="h5 ">Your <span className={'blueText'}>&#8364;{numberWithCommas(location?.state?.amount)}</span> Transfer to <span className="blueText">{location.state?.receiver}</span> was not successful </p>
                        <p className="h6  mgT2">{location?.state?.message} </p>

                        <button className="btn btn-blue mgT2">Please Try Again</button>
                    </div>

                </div>
        </ClientDash>
    );
};

export default TrxFailurePage;