import React from 'react';
import ClientDash from "../../layout/ClientLayout";

const Invoices = () => {
    return (
        <ClientDash title={'Invoices'}>
            <div className="mgT4">
                <h5 className="h5 greyText">Invoices</h5>
            </div>
        </ClientDash>
    );
};

export default Invoices;