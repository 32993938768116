import React, {useState} from 'react';
import {BiCalendar, BiSend} from "react-icons/bi";
import Modal from "./Modal";

const TransactionCard = () => {
    const [show,setShow] = useState(false);
    return <div onClick={()=>setShow(true)} className={'trxCard'}>
        <div className="trxCard--icon trxCard--icon-green">
            <BiSend/>
        </div>
        <div className="trxCard--date">
            <div className="circle-btn">

                <BiCalendar size={24}/>
            </div>
            <p className="h7">Dec 20, 2022</p>
        </div>
        <h5 className="h5 greyText">$56,000.00</h5>
        {
          show?<Modal closeModal={setShow}>
              <p className="h6 greyText">Transaction ID: #njnfnasnlakn</p>

              <h4 className="h4 whiteText mgT2">$5,000 Sent</h4>
              <p className="h6 mgV2 whiteText">Received By Jon Doe</p>
              <p className="h7 mgT2 greyText">Dec 21, 2022</p>
              <div className="failedCard mgT2">
                  <h6 className="h6">Pending</h6>
              </div>
          </Modal>:null
        }
    </div>
};

export default TransactionCard;