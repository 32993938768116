import React, {useState,useContext,useEffect} from 'react';
import ClientDash from "../../layout/ClientLayout";
import Tab from "../../components/tab";
import axiosInstance from "../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import {UserProvider} from "../../hoc/userProvider";
import Loader from "../../components/loader";


const Settings = () => {
    const [tabs] = useState([<AccountSettings/>]);
    const [index,setIndex] = useState(0)
    const ctx = useContext(UserProvider);
    return (
        <ClientDash title={'Account Settings'}>
            <div className="">
                <button onClick={ctx.logOut} className="btn btn-red">Log out</button>
            </div>
            <div className="">

                <Tab tabs={['Account']} activeIndex={index} tabsBody={tabs[index]} onChange={setIndex} />
            </div>
        </ClientDash>
    );
};

function AccountSettings() {

    const userInfo = useContext(UserProvider);
    const user = JSON.parse(userInfo.user)|| JSON.parse(localStorage.getItem('G7_li'))
    const [refresh,setRefresh] = useState(false);
    const [loading,setLoading] = useState(false);
    const [user_,setUser_] = useState({
        firstName:'',
        lastName:"",
        dob:"",
        wallet:{
            totalBalance:0,
            accNo:""
        },
        address:"",
        zipCode:"",
        country:"",
        state:"",
        city:"",
        phone:""
    });
    const getUser = React.useCallback(function () {
        axiosInstance.get(`/sup/acc/user/${user._id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data)
                setUser_(res.data.data);

            })
            .catch(err=>{
                // navigate('/client')
            })


    },[user._id, userInfo.token])
    useEffect(()=>{
        getUser();
        return ()=>{}
    },[getUser])
    function updateInfo(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/acc/update/${user._id}`,{...user_},{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    return <div className={''}>
        <h6 className="h6 ">Account Settings</h6>
        <ToastContainer/>
        <div className="mgT2 grid">
            <div className="grid-item2_4 darkCard-alt">
                {
                    loading?<Loader/>:null
                }

                <form onSubmit={updateInfo} className="mgT2">

                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'First Name'} value={user_?.firstName||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.firstName = e.target.value;setUser_(_old)}}/>
                    </div>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'last Name'} value={user_?.lastName||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.lastName = e.target.value;setUser_(_old)}}/>
                    </div>

                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'Phone'} value={user_?.phone||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.phone = e.target.value;setUser_(_old)}}/>
                    </div>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'Address'} value={user_?.address||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.address = e.target.value;setUser_(_old)}}/>
                    </div>

                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'Country'} value={user_?.country||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.country = e.target.value;setUser_(_old)}}/>
                    </div>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'State'} value={user_?.state||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.state = e.target.value;setUser_(_old)}}/>
                    </div>
                    <div className="form-input-box">
                        <input type="text" className={'form-input'} placeholder={'city'} value={user_?.city||""} onChange={(e)=>{
                            const _old = {...user_} ;
                            _old.city = e.target.value;setUser_(_old)}}/>
                    </div>

                    <button className="btn btn-blue mgT2">Save</button>
                </form>
            </div>
        </div>
    </div>
}

function BankStatement() {
    return <div className={'mgT2'}>
        <h6 className="h6 whiteText">Bank Statement</h6>
        <div className="mgT2">
            <button className="btn btn-sec">Download Pdf</button>
        </div>
    </div>
}

export default Settings;