import  {useEffect, useState} from 'react';

const UseWidth = () => {
    const [width,setWidth] = useState(window.innerWidth);
    useEffect(()=>{
        function onChange(e){
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize',onChange)
        return ()=>{
            window.removeEventListener('resize',onChange)
        }
    },[setWidth])
    return {width}

};

export default UseWidth;