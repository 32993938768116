import React, {useCallback, useEffect, useState} from 'react';
import AdminLayout from "../../layout/AdminLayout";
import Modal from "../../components/Modal";
import AxiosInstance from "../../helpers/axiosInstance";
// import wallet from "../client/subPages/wallet";
import {toast, ToastContainer} from "react-toastify";
import tab2 from "../../assets/key-insti-ebanking-3000x1280.jpg";
import {UserProvider} from "../../hoc/userProvider";
import {Link} from "react-router-dom";


const AdminHome = () => {
    // useRole()
 const [showWalletMod,setShowWalletMod] = useState(false);
 const [pin,setPin] = useState('');
 const [msg,setMsg] = useState('');
 const [info,setInfo] = useState({
     trxCount:0,
     users:0,
     pinReq:0,
     total:0
 })
 const [type,setType] = useState('failure');

    const userInfo = React.useContext(UserProvider);
    function createNewAddress() {
        AxiosInstance.post('/sup/misc/create/pin',{type,msg},{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                toast.success(res.data.msg);
                setPin(res.data.pin);
                setMsg('')
            })
            .catch(err=>{
                toast.error(err.response.data)
            })
    }

    const getAdminInfo = useCallback(()=>{
        AxiosInstance.get('/sup/acc/info/all',{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setInfo(res.data.data)
            })
            .catch(err=>{

            })
    },[userInfo.token])
    useEffect(()=>{
        getAdminInfo()
    },[getAdminInfo])
    return (
        <AdminLayout>
            <div className="Gcenter-all">
                <ToastContainer/>
                <div className="grid-">
                    <div className="grid-item1_3 darkCard">
                        <p className="h7 greyText">Total Amount</p>
                        <h5 className="h5 mgT1 ">&#8364;{info?.total}</h5>
                    </div>

                    <div className="adsCard grid-item2_3" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab2})`,height:'25rem'}}>
                        <h5 className="h5 sans whiteText shadowText width60">Getting to the heart of financial and pension planning</h5>
                    </div>
                </div>
                {
                    showWalletMod?<Modal closeModal={setShowWalletMod}>


                        <h5 className="h5 ">Create Pin</h5>
                        <p className="h7">Generate pin for transactions</p>


                        <h5 className={`${type==='failure'?"redText":"greenText"} h6 mgT2`}>{pin}</h5>
                        <div className="form-input-box">
                            <input type="text" className="form-input" value={msg} onChange={(e)=>{
                            setMsg(e.target.value)}
                            } placeholder={'Msg'}/>
                        </div>
                        <div className="form-input-box">
                            <select className={'form-input'} value={type} onChange={(e)=>{
                            setType(e.target.value)
                            }
                            }>
                                <option>failure</option>
                                <option>success</option>
                            </select>
                        </div>

                        <button onClick={createNewAddress} className="btn btn-blue mgT2">New</button>
                    </Modal>:null
                }
              <div className="display_flex mgT4">
                  <button className="btn btn-blue " onClick={()=>setShowWalletMod(true)}>Generate Pin</button>
                  <Link to={'/admin/complaints'} className="btn btn-primary mgL2" >Check Complaints</Link>

              </div>
            </div>
            <div className="mgT2 Gcenter-all  grid">
                <div className="grid-item1_3 darkCard">
                        <p className="h7 greyText">Total Users</p>
                    <h6 className="h6  mgT2">{info.users}</h6>
                </div>
                <div className="grid-item1_3 darkCard">
                        <p className="h7 greyText">Total Transactions</p>
                    <h6 className="h6  mgT2">{info.trxCount}</h6>
                </div>
                <div className="grid-item1_3 darkCard">
                        <p className="h7 greyText">Total Token Requests</p>
                    <h6 className="h6  mgT2">{info.pinReq}</h6>
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminHome;