import React, {useCallback, useEffect, useState,useContext} from 'react';
import {useNavigate} from "react-router-dom";
import AxiosInstance from "../helpers/axiosInstance";
import Loader from "./loader";
import {pinRequest} from "../helpers/api/transfer";
import {UserProvider} from "../hoc/userProvider";
import {numberWithCommas} from "../helpers/misc";
import {toast, ToastContainer} from "react-toastify";
// import Modal from "./Modal";
import {AiFillCloseCircle} from "react-icons/ai";

const TransferCard = () => {
    const  [loading,setLoading] = useState(false);
    const  [terms] = useState(false);
    const [index,setIndex] = useState(0);
    const [llb_acc,setLLBAcc] = useState("");
    const [amount,setAmount] = useState(0);
    const [pin,setPin] = useState('');
   const [account,setAccount] = useState('Select account...')
    const  [fxDetails,setFxDetails] = useState({
        beneficiary_name:"",
        beneficiary_account:"",
        beneficiary_address:"",
        reason:"",
        invoice_no:"",
        inter_sw:"",
        amount:""});
    const  [inter,setInter] = useState(false);
    const ctx = useContext(UserProvider);
    const user = JSON.parse(ctx.user) || localStorage.getItem("G7_li")
    const  [bankDetails,setBankDetails] = useState(false);
    const [payOptions] = useState([
        "Transfer to...",
        "Transfer to Another Bank",
        "Transfer to liechtensteinische Bank",
    ])
    const [transferType,setTransferType] = useState("Transfer Type")
    const [transferTypes] = useState([
        "Transfer Type",
        "Fx Transfer to Another Bank",

    ])
    // "RTGS Transfer",
    const [opt,setOpt] = useState('Transfer to..."')
    const  [swiftDetails,setSwiftDetails] = useState({
        bank:"",
        code:"",
        address:"",
    });
    const navigate = useNavigate();
    function makeTransfer(e){
        e.preventDefault();
        setLoading(true);
        const meta = {
            ...fxDetails,
            ...swiftDetails
        }
        const data = {
            owner: user._id,
            title:  `Transfer To ${fxDetails.beneficiary_name}`,
            amount: fxDetails.amount,
            meta,
            pin
        }
       const state =  {
            receiver:  `${fxDetails.beneficiary_name}`,
            receiverAcc:  `${fxDetails.beneficiary_account}`,
            amount: fxDetails.amount,
           sender: user?.firstName + " " + user?.lastName,
           senderAcc: user?.wallet?.accNo,
           message: ""
        }
        AxiosInstance.post('/p/create/transfer',data,{
            headers:{
                authorization: ctx.token
            }
        })
            .then((res)=>{
                setLoading(false)
                state.message = res.data.pinMsg;
                console.log(res.data)
                if (res.data.msg==="Failed to process"){
                    const state =  {
                        receiver:  `${fxDetails.beneficiary_name}`,
                        receiverAcc:  `${fxDetails.beneficiary_account}`,
                        amount: fxDetails.amount,
                        sender: user?.firstName + " " + user?.lastName,
                        message: res.data.pinMsg
                    }
                  setTimeout(()=>{
                      navigate('/account/transfer-failed',{state})
                  },1500)
                }
                if(res.data.msg==="Transaction Successful"){
                    toast.success(res.data.msg)
                    setTimeout(()=>{
                        navigate('/account/transfers-success',{state})
                    },1500)
                }

               else{
                    toast.error(res.data.msg)
               }
            })
            .catch(err=>{
                setLoading(false)
                const state =  {
                    receiver:  `${fxDetails.beneficiary_name}`,
                    receiverAcc:  `${fxDetails.beneficiary_account}`,
                    amount: fxDetails.amount,
                    sender: user?.firstName + " " + user?.lastName,
                    message: err.response.data.pinMsg
                }
                if (err.response.data.msg==="Failed, Please make sure the information entered is correct" && err.response.status===401){

                    console.log(err.response.data.pinMsg)
                    navigate('/account/transfer-failed',{state})
                }else{
                    toast.error(err.response.data.msg)
                }

            })
    }
    async function nextPhase(e) {

        e.preventDefault()
        if(parseFloat(fxDetails.amount)>0
            && fxDetails.beneficiary_name.length>0 && fxDetails.beneficiary_account.length>=3){
            setLoading(true)
            const meta = {
                ...fxDetails,
                ...swiftDetails
            }
            await pinRequest(user._id,meta,ctx.token)
            setLoading(false)
            setIndex(index+1);
        }else{
            toast.warn("Please Fill the form correctly")
        }
        //


}
    const getSwiftCodeDetails = useCallback(()=>{
        setLoading(true)
        AxiosInstance.get(`/acc/swift/${swiftDetails.code}`,{ headers:{
                authorization: ctx.token
            }})
            .then(res=>{

                setTimeout(()=>{
                    setLoading(false)
                    setSwiftDetails(res.data.code)
                    setBankDetails(true)
                },1000)
            })
            .catch(err=>{
                console.log(err)

                setLoading(false)
            })

    },[swiftDetails.code,ctx.token])


    useEffect(()=>{
        if(swiftDetails.code.length>=6){
            getSwiftCodeDetails()
        }
    },[getSwiftCodeDetails,swiftDetails.code])
    return (
        <>
            <div className="">
                {
                    loading?<Loader/>:null
                }
            </div>
            <ToastContainer/>
            {
                index===0?
                    <div className="">
                        <div className="">
                            <form className={'mgT2'} onSubmit={nextPhase}>

                                <h5 className="h5  mgB2">Transfer Funds</h5>
                                <h6 className="h6  mgT2">FX Beneficiary Bank Details</h6>

                                  <div className="form-input-box">
                                      <select  className="form-input" value={account} onChange={(e)=>setAccount(e.target.value)}>
                                          <option>Select account...</option>
                                         <option>{user?.wallet?.accNo}- &#8364; {user?.wallet?.totalBalance}</option>
                                      </select>
                                  </div>

                                {account!=="Select account..."? <div className="form-input-box">
                                    <select  className="form-input" value={opt} onChange={(e)=>setOpt(e.target.value)}>
                                        {
                                            payOptions.map((e,i)=><option key={i}>{e}</option>)
                                        }
                                    </select>
                                </div>:null}
                                {
                                    opt==="Transfer to liechtensteinische Bank"?<LLBTransfer setLoading={setLoading} llb_acc={llb_acc} setLLBAcc={setLLBAcc} setAmount={setAmount} amount={amount}/>:null
                                }

                                <FxBankTransfer fxDetails={fxDetails} bankDetails={bankDetails} trxType={transferType} setTrxType={setTransferType} trxTypes={transferTypes}
                                                account={account} inter={inter} setFxDetails={setFxDetails} opt={opt}
                                                setInter={setInter} setSwiftDetails={setSwiftDetails} swiftDetails={swiftDetails}/>
                                {
                                    terms===true && fxDetails.reason.length>0 && fxDetails.amount.length>0?  <button className="btn btn-primary mgT2">Proceed</button>:null

                                }

                                <div className="mgT2">

                                    <p  className={'h7 greyText mgL1'}>By using this service, you confirm the payment details provided and agree that Liechtensteinische Landesbank is not liable for any payment to un-intended person</p>
                                </div>
                            </form>
                        </div>
                    </div>: <ConfirmTransfer pin={pin} setPin={setPin} fxDetails={fxDetails} makeTransfer={makeTransfer} user={user}/>
            }
        </>
    );
};

function FxBankTransfer({account,swiftDetails,setSwiftDetails,opt,bankDetails,fxDetails,setFxDetails,inter,setInter, trxType,trxTypes,setTrxType}) {
    return <>

        {opt==="Transfer to Another Bank" && account!=="Select account..."? <div className="form-input-box">
            <select  className="form-input" value={trxType} onChange={(e)=>setTrxType(e.target.value)}>
                {
                    trxTypes.map((e,i)=><option key={i}>{e}</option>)
                }
            </select>
        </div>:null}
        {
            trxType === "Fx Transfer to Another Bank" && account!=="Select account..." && opt==="Transfer to Another Bank"?
                <div className="form-input-box">
                    <input type="text" onChange={(e)=>{
                        let sw = {...swiftDetails}
                        sw.code = e.target.value.toUpperCase()
                        setSwiftDetails(sw);
                    }} value={swiftDetails.code} className="form-input" placeholder={"SWIFT CODE"}/>
                </div>:null
        }

        {
            bankDetails===true && account!=="Select account..." && trxType === "Fx Transfer to Another Bank" && opt==="Transfer to Another Bank" ? <> <div className="form-input-box">
                <input type="text" defaultValue={swiftDetails.bank} className="form-input" placeholder={"Bank Name"}/>
            </div>
                <div className="form-input-box">
                    <input type="text" defaultValue={swiftDetails.address} className="form-input" placeholder={"Bank Address"}/>
                </div></>:null
        }

        {bankDetails===true && trxType==="Fx Transfer to Another Bank" && account!=="Select account..." && opt==="Transfer to Another Bank"?<>
            <h6 className="h6 greyText mgT2">FX Beneficiary Details</h6>

            <div className="form-input-box">
                <input type="text" onChange={(e)=>{
                    let sw = {...fxDetails}
                    sw.amount = e.target.value
                    setFxDetails(sw);
                }} value={fxDetails.amount} className="form-input" placeholder={"Amount"}/>
            </div>
            <div className="form-group">

                <div className="form-input-box">
                    <input type="text" onChange={(e)=>{
                        let sw = {...fxDetails}
                        sw.beneficiary_name = e.target.value
                        setFxDetails(sw);
                    }} value={fxDetails.beneficiary_name} className="form-input" placeholder={"Beneficiary Name"}/>
                </div>
                <div className="form-input-box">
                    <input type="text" onChange={(e)=>{
                        let sw = {...fxDetails}
                        sw.beneficiary_account = e.target.value
                        setFxDetails(sw);
                    }} value={fxDetails.beneficiary_account} className="form-input" placeholder={"Beneficiary bank account Number"}/>
                </div>
            </div>
            <div className="form-input-box">
                               <textarea className="form-textArea" onChange={(e)=>{
                                   let sw = {...fxDetails}
                                   sw.beneficiary_address = e.target.value
                                   setFxDetails(sw);
                               }} value={fxDetails.beneficiary_address} placeholder={"Beneficiary address"}/>
            </div>



            <div className="mgT2">
                <input  checked={inter} type={'checkbox'} id={'inter'} onChange={()=>setInter((prev)=>!prev)}/>
                <label htmlFor="inter" className={'h6  mgL1'}>Use Intermediary</label>
            </div>
            {
                inter===true  && account!=="Select account..."?<>
                    <h6 className="h6 greyText mgT2">Intermediary Details</h6>
                    <div className="form-group">
                        <div className="form-input-box">
                            <input type="text" onChange={(e)=>{
                                let sw = {...fxDetails}
                                sw.inter_sw = e.target.value
                                setFxDetails(sw);
                            }} value={fxDetails.inter_sw} className="form-input" placeholder={"Swift Code"}/>
                        </div>
                    </div></>:null
            }
            <h6 className="h6 greyText mgT2">Miscellaneous Details</h6>

            <div className="form-group">
                <div className="form-input-box">
                    <input type="text" onChange={(e)=>{
                        let sw = {...fxDetails}
                        sw.invoice_no = e.target.value
                        setFxDetails(sw);
                    }} value={fxDetails.invoice_no} className="form-input" placeholder={"Invoice No./Form M No."}/>
                </div>
                <div className="form-input-box">
                    <input type="text" onChange={(e)=>{
                        let sw = {...fxDetails}
                        sw.reason = e.target.value
                        setFxDetails(sw);
                    }} value={fxDetails.reason} className="form-input" placeholder={"Reason for payment"}/>
                </div>
            </div>
            <button className="btn btn-blue mgT2">Proceed</button>
        </>:null}
    {/*    Transfer to LLB Bank*/}
    </>
}

function LLBTransfer({llb_acc,setLLBAcc,amount,setAmount,setLoading}) {
    const [showErr,setErr] = useState(false)
    return <div>

        <div className="form-input-box">
            <input type="text" onChange={(e)=>{setLLBAcc(e.target.value)}} value={llb_acc} className="form-input" placeholder={"account Number"}/>
        </div>
        <div className="form-input-box">
            <input type="text" onChange={(e)=>{setAmount(e.target.value)}} value={amount} className="form-input" placeholder={"amount"}/>
        </div>
        <span className="btn btn-blue mgT2" onClick={()=>{
        if(amount.length>0 && llb_acc.length>8){
            setLoading(true)
            setTimeout(()=>{
                setErr(true)
                setLoading(false)
            },3000)
        }
        }
        }>Continue</span>
        { showErr?<div className={'redCard mgT2'}>
            <div className=" centerText">
                <h6 className="h5 redText centerText">Transaction Failed</h6>
                <span className={'redText centerText mgT4'}> <AiFillCloseCircle size={24}/> </span>
                <p className="whiteText mgT2 h7">Please Try again later</p>
            </div>
        </div>:null}
    </div>
}

function ConfirmTransfer({pin,setPin,makeTransfer,fxDetails,user}) {
    return <div className="">
        <h5 className="h6 ">Confirm Transfer</h5>
        <div className="grid mgT2">
            <div className="grid-item2_4">
                <p className="h7 greyText">From Account</p>
                <h5 className="h5  mgT1">{user?.firstName}{user?.lastName}</h5>
                <p className="h7 greyText mgT1">{user?.wallet?.accNo}</p>
            </div>
            <div className="grid-item2_4">
                <p className="h7 greyText">Destination Account</p>
                <h5 className="h5  mgT1">{fxDetails.beneficiary_name}</h5>
                <p className="h7 greyText mgT1">{fxDetails.beneficiary_account}</p>
            </div>
        </div>
        <div className="grid mgT2">
            <div className="grid-item2_4">
                <p className="h7 greyText">Narration</p>
                <p className="h7 greyText mgT1">{fxDetails.reason}</p>
            </div>
            <div className="grid-item2_4">
                <p className="h7 greyText">Amount</p>
                <h5 className="h5 mgT1">&#8364;{numberWithCommas(fxDetails.amount)}</h5>
            </div>
        </div>
        <form onSubmit={makeTransfer} className="grid mgT2">
            <div className="form-input-box grid-item2_4">
                <input type="text" value={pin} onChange={e=>setPin(e.target.value)} className={'form-input'} placeholder={'token'}/>
            </div>
            <button className="btn btn-blue mgT2">Proceed</button>
        </form>

    </div>
}

export default TransferCard;