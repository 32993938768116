import React, {useContext} from 'react';
import ClientDash from "../../layout/ClientLayout";
import Table from "../../components/Table";
import {UserProvider} from "../../hoc/userProvider";
import axiosInstance from "../../helpers/axiosInstance";
import moment from "moment";
const Transactions = () => {
    const [transactions,setTransactions] = React.useState([]);
    // const [pg,setPg] = React.useState(1);
    // const [totPages,setTotPages] = React.useState(1);
    function formatDate(value) {
        return  moment(value).format('MMMM DD YYYY')
    }

    const userInfo = useContext(UserProvider);
    const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    const getTransactions = React.useCallback(()=>{
        axiosInstance.get(`/trx/get/statement/${user._id}/`,{headers:{
                authorization: userInfo.token
            }})
            .then((res)=>{
                console.log(res.data.data);
                setTransactions(res.data.data)
            })
            .catch(err=>{
                console.log(err);
            })
    },[setTransactions,userInfo.token,user._id])
    React.useEffect(()=>{
        getTransactions();
    },[getTransactions])
    return (

        <ClientDash title={'All Transactions'}>

            <div className="mgT4 outlined-card">
                <Table filters={true} title={'statement-of-account'} type={'dark-table'} data={transactions} headers={['Title','Type','Amount','Date','Status']} body={<>
                    {
                        transactions.length>0?
                            transactions.map((e)=><tr key={e._id}>
                                <td>{e.title}</td>
                                <td>{e.trxType}</td>
                                <td>{e.amount}</td>
                                <td>{formatDate(e?.createdAt)}</td>
                                <td>{e?.status}</td>
                            </tr>):null
                    }
                </>}/>
            </div>

        </ClientDash>
    );
};

export default Transactions;