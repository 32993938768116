export function createCaptcha(canvas) {
    //clear the contents of captcha div first
    // document.getElementById('captcha').innerHTML = "";
    //

    let code = ""
    var charsArray =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) === -1)
            captcha.push(charsArray[index]);
        else i--;
    }

    canvas.id = "captcha";
    canvas.width = 100;
    canvas.height = 22;
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = "16px Georgia";
    ctx.strokeText(captcha.join(""), 0, 15);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    code = captcha.join("");

    return {
        code
    }
    // adds the canvas to the body element
}


// function validateCaptcha() {
//     event.preventDefault();
//     debugger
//     if (document.getElementById("cpatchaTextBox").value == code) {
//         alert("Valid Captcha")
//     }else{
//         alert("Invalid Captcha. try Again");
//         createCaptcha();
//     }
// }
