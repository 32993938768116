export function handleValidate(e,type) {
    switch (type){
        case 'email':

            return e.length>0;

        case 'password':

            return e.length>=4;
        default:
            break;
    }
}