import React, {useContext} from 'react';
import {UserProvider} from "./userProvider";
import {Navigate} from "react-router-dom";

const PrivateRoute = ({children}) => {
    const user = useContext(UserProvider);
    const _user = JSON.parse(localStorage.getItem('user__')) || JSON.parse(user.user);
    //
    return user.token && (user.role==='1' || _user.role===1)?children :<Navigate to={'/'} replace/>
};

export default PrivateRoute;