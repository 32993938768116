import React, {useState} from 'react';
import Navigation from "../../components/Navigation";
import Carousel from "../../components/carousel";
import Footer from "../../components/Footer";
import AltTab from "../../components/Alttab";
import SlideCards from "./SlideCards";
import tab1Lg from '../../assets/tab1lg.jpg'
import tab1Lg2 from '../../assets/tab1lg2.jpg'
import tab1Lg3 from '../../assets/tab1lg3.jpg'
import tab2Lg2 from '../../assets/tab2lg2.jpg'
import foreign from '../../assets/foreign_risk.png';
import finance from '../../assets/financ.png'
import keySol from '../../assets/key-xpsolutions-3000x1280.jpg'
import keySol2 from '../../assets/lead-xpsolutions1-3000x1280.jpg'
import tailored from '../../assets/tailred.jpg'
import tv from '../../assets/tv-.svg'
import tab from '../../assets/institutional1.jpg'
import {Link} from "react-router-dom";
const Institutional = () => {
    const [index,setIndex] = useState(0)
    const [tabs] = useState([<Tab1/>,<Tab2/>,<Tab3/>])
    return (
        <div>
            <Navigation
                bottomTabsContent={[<DropDown1/>,<DropDown2/>,<DropDown3/>]}
                bottomTabs={["Intermediaries","Asset Management","Fund Services"]}/>
            <Carousel/>
            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">The best for your assets</h5>
                <p className="h5 mgT2">As the bank with the richest tradition in Liechtenstein and as an international corporate group, we have offered security and stability to our clients for 160 years. Clients appreciate our tradition and our innovative strength. Take advantage of our outstanding expertise and services – we are here for you.</p>
            </div>
            <div className="">
                <>
                    <AltTab tabs={['Private Clients','Corporate Clients','Institutional Client']} activeIndex={index} tabsBody={tabs[index]} onChange={setIndex}/>
                </>
            </div>
            <div className="mgV8 center-all">
                <div className="grid-" style={{backgroundColor:"#f2f1f0"}}>
                    <div className="grid-item1_4" style={{backgroundImage:`url(${tv})`, backgroundPosition:"center",
                        backgroundRepeat:"no-repeat",
                        backgroundSize:"cover", padding:"4rem",}}>
                        {/*<img src={tv} alt="tv" className={'fluidImg'}/>*/}
                    </div>
                    <div className="grid-item3_4" style={{padding:"2rem"}}>
                        <h5 className="h5 sans dark-green">Your entry to Online Banking. Register now with your user data and launch Online Banking.</h5>
                        <button className="btn btn-red mgT4 h5 sans">Register</button>
                    </div>
                </div>
            </div>

            <div className=" mgV8 center-all width45 ">
                <h5 className="h4 sans dark-green">Current media communiqués</h5>
                <p className="h5 mgT2">We rely on transparency and up-to-date information so that our clients, investors, and partners always have the best possible information at their disposal. Access our latest news & communiqués and stay up to date on what moves the LLB Group.</p>
            </div>

            <div className="center-all">
                <SlideCards/>
            </div>
            <Footer/>
        </div>
    );
};

function DropDown3() {
    return <div>

        <h6 className="h6 greenText">LLB Xpert solutions</h6>
        <p className="h6 greyText mgT1">Pioneering solutions tailored to your needs.
        </p>
        <Link to={'/'} className={'h6 blueText no-deco mgT1'}>Overview</Link>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Private label funds</h5>
                <ul className={'list'}>
                    <li>Overview</li>
                    <li>Fund formation</li>
                    <li>Your added value</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Custodian Bank</h5>
                <ul className={'list'}>
                    <li>Overview</li>
                    <li>Your added value</li>
                    <li>Partners</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Leichtenstein fund centre</h5>
                <ul className={'list'}>
                    <li>Location advantages
                       </li>
                    <li> Regulation</li>
                    <li> Authorities & business associations</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">LLB Fund services</h5>
                <ul className={'list'}>
                    <li>Philosophy</li>
                    <li>Funds & building blocks</li>
                    <li> Investor information</li>
                    <li>Contact</li>
                </ul>
            </div>
        </div>

        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab})`}}>
            <h5 className="h5 sans whiteText shadowText">Knowledge transfer & networking with LLB Xpert Views</h5>
            <button className="btn-outlined btn-outlined-white mgT2">Find out more</button>
        </div>
    </div>
}
function DropDown2() {
    return <div>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Asset Management</h5>
                <ul className={'list'}>
                    <li>About us</li>
                    <li> Philosophy
                        </li>
                    <li>Awards</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Our Solutions</h5>
                <ul className={'list'}>
                    <li>

                        Mandates


                    </li>
                    <li>  Investment process</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Investments</h5>
                <ul className={'list'}>
                    <li>Funds & Building blocks
                    </li>
                    <li>Performance & reporting</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Publications</h5>
                <ul className={'list'}>
                    <li>Capital Markets

                    </li>
                    <li>Market Updates</li>
                    <li>Investor Information</li>
                </ul>
            </div>
        </div>
        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab})`}}>
            <h5 className="h5 sans whiteText shadowText">Knowledge transfer & networking with LLB Xpert Views</h5>
            <button className="btn-outlined btn-outlined-white mgT2">Find out more</button>
        </div>
    </div>
}
function DropDown1() {
    return <div>

        <h6 className="h6 greenText">LLB Xpert solutions</h6>
        <p className="h6 greyText mgT1">Pioneering solutions tailored to your needs.
        </p>
        <Link to={'/'} className={'h6 blueText no-deco mgT1'}>Overview</Link>
        <div className="grid- mgT4">
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Products & Services</h5>
                <ul className={'list'}>
                    <li>LLB Xpert monitoring</li>
                    <li>LLB Invest</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Digital Products & Services</h5>
                <ul className={'list'}>
                    <li>LLB Connect</li>
                    <li>EBICS</li>
                    <li>LLB DataFeed</li>
                    <li>Digital Signature</li>
                    <li>Form Centre</li>
                    <li>LLB Mobile Banking</li>
                    <li>LLB Online Banking</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Touch points</h5>
                <ul className={'list'}>
                    <li>LLB Xpert Views</li>
                    <li>Events</li>
                    <li>Personal Contacts</li>
                </ul>
            </div>
            <div className="grid-item1_4">
                <h5 className="h6 greenText">Pricing</h5>
                <ul className={'list'}>
                    <li>Overview</li>
                </ul>
            </div>
        </div>

        <div className="navigation_bottom_ads mgT4" style={{backgroundImage:`linear-gradient(#0004,#0005),url(${tab})`}}>
            <h5 className="h5 sans whiteText shadowText">Knowledge transfer & networking with LLB Xpert Views</h5>
            <button className="btn-outlined btn-outlined-white mgT2">Find out more</button>
        </div>
    </div>
}

function Tab3() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${keySol})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Give your clients a decisive edge--LLB Xpert Solutions</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${keySol2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">More time for your clients - LLB Experts Solutions</h4>
            </div>
            <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
        </div>
    </div>
}
function Tab2() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${tailored})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Tailored banking relationships</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab2Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Digital Offerings</h4>
            </div>
            <div className="grid">
                <div className="mgT2 grid-item2_4" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${finance})`,borderRadius:".25rem",backgroundSize:'cover',
                    backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                    <h4 className="h5 sans shadowText whiteText">Financing</h4>
                </div>
                <div className="grid-item2_4">
                    <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${foreign})`,borderRadius:".25rem",backgroundSize:'cover',
                        backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                        <h4 className="h5 sans shadowText whiteText">Foreign Current Risks</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
function Tab1() {
    return <div className={'grid'}>
        <div className="grid-item2_4" style={{backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg})`,height:'52vh',backgroundSize:'cover',
            backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
            <p className="h5 sans shadowText whiteText">Your desired banking relationship--LLB invest</p>
        </div>
        <div className="grid-item2_4">
            <div className="" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg2})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
            <div className="mgT2" style={{height:"25vh",backgroundImage:`linear-gradient(#0005,#0006),url(${tab1Lg3})`,borderRadius:".25rem",backgroundSize:'cover',
                backgroundPosition:"center",display:'flex',flexDirection:"column",justifyContent:"flex-end",padding:"2rem"}}>
                <h4 className="h5 sans shadowText whiteText">Your Assets are in good hands --LLB invest</h4>
            </div>
        </div>
    </div>
}
export default Institutional;