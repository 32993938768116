import React, {useEffect, useState,useContext} from 'react';
import AdminLayout from "../../../layout/AdminLayout";
import {UserProvider} from "../../../hoc/userProvider";
import axiosInstance from "../../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../components/loader";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import {numberWithCommas} from "../../../helpers/misc";
import Moment from "react-moment";
import {useNavigate, useParams} from "react-router-dom";
import AxiosInstance from "../../../helpers/axiosInstance";

const AdminStatement = () => {
    const [showEdit,setShowEdit] = useState(false);
    const [showDel,setShowDel] = useState(false);
    const [loading,setLoading] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [editAcc,setEditAcc] = useState(false);
    const [showBalEdit,setBalEdit] = useState(false);
    const [searchText,setSearchText] = useState('');
    const navigate = useNavigate();

    const [showCreateTrx,setCreateTrx] = useState(false);
    const [trx,setTrx] = useState({
        amount:0,
        owner:"",
        trxType:"",
        status:"",
        createdAt: Date.now(),
        title:"",

    });
    const userInfo = useContext(UserProvider);
    // const user = JSON.parse(userInfo.user) || JSON.parse(localStorage.getItem("G7_li"));
    const [user_,setUser_] = useState({
        firstName:'',
        lastName:"",
        dob:"",
        wallet:{
            totalBalance:0,
            accNo:"",
            _id:""
        },
        address:"",
        zipCode:"",
        country:"",
        state:"",
        city:"",
        phone:""
    });
    const [current,setCurrent] = useState({
        amount:0,
        owner:""
    });
    const {id} = useParams();
    function createTrx() {
      const newTrx = {
          ...trx
      }
      newTrx.owner = user_._id;
      newTrx.wallet = user_?.wallet._id;
      console.log(newTrx)
      AxiosInstance.post(`/sup/trx/create/new`,newTrx,{
          headers:{
              authorization:userInfo.token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
      })
          .then((res)=>{
             toast.success(res.data.msg)
              setCreateTrx(false)
              setTrx({ amount:0,
                  owner:"",
                  trxType:"",
                  status:"",
                  createdAt: Date.now(),
                  title:""})
              setRefresh(!refresh)
          })
          .catch(err=>{
              toast.error(err.response.data.msg)
          })
    }
    function changeAcc(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/misc/update/`,{...user_.wallet},{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setBalEdit(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function updateInfo(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/acc/update/user/${id}`,{...user_},{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setEditAcc(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    const [transactions,setTransactions] = React.useState([]);
    function searchQuery(e) {
        e.preventDefault();
        axiosInstance.post(`/sup/trx/search/?q=${searchText}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data)
                setTransactions(res.data.data);
            })
            .catch(err=>{
                console.log(err)
            })
    }
    const getAll = React.useCallback(function () {
        axiosInstance.get(`/sup/trx/get/all/${id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setTransactions(res.data.data);
            })
            .catch(err=>{
                console.log(err)
            })


    },[userInfo.token,setTransactions,id])
    const getUser = React.useCallback(function () {
        axiosInstance.get(`/sup/acc/user/${id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                console.log(res.data)
                setUser_(res.data.data);
            })
            .catch(err=>{
                navigate('/client')
            })


    },[userInfo.token,setUser_,id,navigate])
    function updateTrx(e) {
        e.preventDefault();
        setLoading(true)
        axiosInstance.post(`/sup/trx/update/`,current,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowEdit(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function deleteTrx() {
        setLoading(true)
        axiosInstance.delete(`/sup/trx/delete/${current._id}`,{
            headers:{
                authorization:userInfo.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res=>{
                setLoading(false)
                toast.success(res.data.msg);
                setRefresh(!refresh)
                setTimeout(()=>{
                    setShowDel(false)
                },1000)
            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    useEffect(()=>{
        if (searchText.length===0){
            getAll();
        }
        return ()=>{}
    },[getAll,refresh,searchText])
    useEffect(()=>{
     getUser();
        return ()=>{}
    },[getUser])
    return (
        <AdminLayout title={'Statement of account'}>
          <div className="Gcenter-all">
              {
                  loading?<Loader/>:null
              }
              <ToastContainer/>
              {
                  showCreateTrx?
                      <Modal closeModal={setCreateTrx}>
                          <h6 className="h6">Create Transaction</h6>
                          <p className="h7 greenText mgT1">The owner is the currently opened statement</p>

                          <div className="form-input-box">
                              <select  value={trx.trxType|| ""}  onChange={(e)=>{
                                  const old = {...trx};
                                  old.trxType = e.target.value;
                                  setTrx(old)
                              }
                              }  placeholder={'status'} className={'form-input'}>
                                  <option>Transaction Type...</option>
                                  <option>Debit</option>
                                  <option>Credit</option>
                              </select>
                          </div>
                          <div className="form-input-box">
                              <input type="text" value={trx.title|| ""}  onChange={(e)=>{
                                  const old = {...trx};
                                  old.title = e.target.value;
                                  setTrx(old)
                              }
                              } placeholder={'title'} className={'form-input'}/>
                          </div>
                          <div className="form-input-box">
                              <input type="text" value={trx.amount|| ""}  onChange={(e)=>{
                                  const old = {...trx};
                                  old.amount = e.target.value;
                                  setTrx(old)
                              }
                              } placeholder={'amount'} className={'form-input'}/>
                          </div>
                          <div className="form-input-box">
                              <select  value={trx.status|| ""}  onChange={(e)=>{
                                  const old = {...trx};
                                  old.status = e.target.value;
                                  setTrx(old)
                              }
                              }  placeholder={'status'} className={'form-input'}>
                                  <option>Status...</option>
                                  <option>success</option>
                                  <option>pending</option>
                                  <option>failed</option>
                              </select>
                          </div>
                          <div className="form-input-box">
                              <input type="date" value={trx.createdAt|| Date.now()} onChange={(e)=>{
                              const old = {...trx};
                              old.createdAt = e.target.value;
                              setTrx(old)
                              }
                              } placeholder={''} className={'form-input'}/>
                          </div>
                          <button className="btn btn-blue" onClick={createTrx}>Create</button>
                      </Modal> :null
              }
              {
                  showDel?<Modal closeModal={setShowDel}>
                      <h5 className="h5">Are you sure you want to delete?</h5>
                      <button className="btn btn-red mgT2" onClick={deleteTrx}>Delete</button>
                  </Modal>:null
              }
              {
                  editAcc?
                      <Modal closeModal={setEditAcc}>
                          <h5 className="h5 ">Edit User Info</h5>
                          <form onSubmit={updateInfo} className="mgT2">

                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'First Name'} value={user_?.firstName||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.firstName = e.target.value;setUser_(_old)}}/>
                              </div>
                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'last Name'} value={user_?.lastName||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.lastName = e.target.value;setUser_(_old)}}/>
                              </div>

                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'Phone'} value={user_?.phone||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.phone = e.target.value;setUser_(_old)}}/>
                              </div>
                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'Address'} value={user_?.address||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.address = e.target.value;setUser_(_old)}}/>
                              </div>

                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'Country'} value={user_?.country||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.country = e.target.value;setUser_(_old)}}/>
                              </div>
                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'State'} value={user_?.state||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.state = e.target.value;setUser_(_old)}}/>
                              </div>
                              <div className="form-input-box">
                                  <input type="text" className={'form-input'} placeholder={'city'} value={user_?.city||""} onChange={(e)=>{
                                      const _old = {...user_} ;
                                      _old.city = e.target.value;setUser_(_old)}}/>
                              </div>

                              <button className="btn btn-blue mgT2">Save</button>
                          </form>
                      </Modal>:null
              }
              {
                  showBalEdit?<Modal closeModal={setBalEdit}>
                      <h5 className="h5 ">Edit User Balance</h5>
                      <form onSubmit={changeAcc}>
                          <div className="form-input-box">
                              <input type="text" className={'form-input'} placeholder={'total balance'} value={user_?.wallet?.totalBalance||""} onChange={(e)=>{
                                  const _old = {...user_} ;
                                  _old.wallet.totalBalance = e.target.value;setUser_(_old)}}/>
                          </div>

                          <button className="btn btn-blue mgT2">Save</button>
                      </form>
                  </Modal>:null
              }
              <div className="mgT2">
                  <div className="darkCard">

                      <div className="grid">
                          <div className="grid-item2_4">
                              <p className="h7 greyText">Full Name</p>
                              <h5 className="h6  mgT2">{user_?.firstName} {user_?.lastName}</h5>
                              <p className="h7 greyText">Email</p>
                              <h5 className="h6  mgT2">{user_?.email}</h5>
                              <p className="h7 greyText mgT2">Date Created</p>
                              <h5 className="h6  mgT2">
                                  <Moment format={"DD MMMM YYYY"}>
                                      {user_?.dob}
                                  </Moment>
                              </h5>
                          </div>
                          <div className="grid-item1_4">
                              <p className="h7 greyText">Address</p>
                              <h5 className="h6  mgT1">{user_?.address} {user_?.state}</h5>
                              <p className="h7 greyText">State</p>
                              <h5 className="h6  mgT1"> {user_?.state}</h5>
                              <p className="h7 greyText">City</p>
                              <h5 className="h6  mgT1">{user_?.city}</h5>
                          </div>
                          <div className="grid-item1_4">
                              <p className="h7 greyText">Zip Code</p>
                              <h5 className="h6  mgT1"> {user_?.zipCode}</h5>
                              <p className="h7 greyText">Phone</p>
                              <h5 className="h6  mgT1">{user_?.phone}</h5>
                          </div>
                      </div>
                      <hr className="hr mgV2"/>
                      <div className="grid">
                          <div className="grid-item1_4 altCard">
                              <h6 className=" h7">Account Bal.</h6>
                              <h5 className="h5  mgT2">&#8364;{numberWithCommas(user_?.wallet?.totalBalance)}</h5>
                          </div>
                          <div className="grid-item1_4 altCard">
                              <h6 className=" h7">Account No.</h6>
                              <h5 className="h5  mgT2">{user_?.wallet?.accNo}</h5>
                          </div>
                      </div>


                      { user_?.kyc?.passportPhoto?
                          <div className={'mgT2'}>
                              <hr className="hr mgV2"/>
                              <h6 className="h6">KYC info</h6>
                              <div className="altCard grid- mgT2">

                                  {
                                      user_.kyc?.passportPhoto?.map(e=>{
                                          return <div className="grid-item2_4" key={e}>
                                              <img src={e} alt="" className={"fluid_img"}/>
                                          </div>
                                      })
                                  }
                              </div>
                          </div>
                          :null}
                      <div className="mgT2 display_flex">
                          <button onClick={()=>setEditAcc(true)} className="btn btn-blue">Edit UserInfo</button>
                          <button onClick={()=>setBalEdit(true)} className="btn btn-blue">Edit Balance</button>
                          <button onClick={()=>setCreateTrx(true)} className="btn btn-blue">Create Transaction</button>

                      </div>
                  </div>
              </div>
              {
                  showEdit?<Modal closeModal={setShowEdit}>
                      <h5 className="h5">Edit</h5>
                      <form onSubmit={updateTrx} className="mgT2">
                          <div className="form-input-box">
                              <input type="text" placeholder={'Amount'} className={'form-input'} value={current.amount} onChange={(e)=>{
                                  let _cur = {...current};

                                  _cur.amount = e.target.value;

                                  setCurrent(_cur);

                              }
                              }/>
                          </div>
                          <div className="form-input-box">
                              <input type="text" placeholder={'owner'} className={'form-input'} value={current?.owner.firstName + " " + current?.owner.lastName}/>
                          </div>
                          <div className="form-input-box">
                              <select value={current.status} className={'form-input'} onChange={(e)=>{
                                  let _cur = {...current};

                                  _cur.status = e.target.value;

                                  setCurrent(_cur);

                              }
                              }>
                                  <option >pending</option>
                                  <option >Failed</option>
                                  <option >success</option>
                              </select>
                          </div>
                          <button className="btn btn-green">Save</button>
                      </form>
                  </Modal>:null
              }
              <div className="mgT2 outlined-card">
                  <Table
                      searchText={searchText}
                      onTextChange={setSearchText}
                      searchQuery={searchQuery}
                      filters={true}
                      type='dark-table' headers={['TXN-Type','Amount','title',
                      'Reference No','owner','Status','Date','Action']}
                      body={
                          <>
                              {transactions.length>0?
                                  transactions.map(tr=>{
                                      return   (
                                          <tr key={tr._id} >

                                              <td>
                                                  {tr.trxType}
                                              </td>

                                              <td>
                                                  &#8364;{numberWithCommas(tr?.amount||0)}
                                              </td>

                                              <td>
                                                  {tr.title}
                                              </td>
                                              <td>
                                                  {tr?.ref_id?tr.ref_id:""}
                                              </td>
                                              <td>{tr?.owner?.firstName} {tr?.owner?.lastName}</td>
                                              <td>
                                                  <span className={tr.status==='pending'?"blue-text":tr.status==='success'?'green-text':"redText"}>
                                                      {/*?'green-text':'emMd'*/}
                                                      {tr.status}
                                                      {/*{tr.status}*/}
                                                  </span>
                                              </td>

                                              <td>
                                                  <Moment format={'DD MMMM YYYY HH:mm:ss'}>{tr.createdAt}</Moment>
                                              </td>
                                              <td>
                                                  <button onClick={()=>{
                                                      setCurrent(tr);
                                                      setShowEdit(true)
                                                  }} className="btn btn-blue">Edit</button>
                                                  <button className="btn btn-red mgL2" onClick={()=>{
                                                      setCurrent(tr);
                                                      setShowDel(true)}
                                                  }>Delete</button>
                                              </td>

                                          </tr>)}):
                                  <tr><td colSpan='7'></td></tr>
                              }
                          </>
                      }
                  />
              </div>
          </div>
        </AdminLayout>
    );
};

export default AdminStatement;