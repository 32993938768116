import React, {useState, useContext, useCallback, useEffect} from 'react';
import ClientLayout from "../../layout/ClientLayout";
import AxiosInstance from "../../helpers/axiosInstance";

import {UserProvider} from "../../hoc/userProvider";
import {toast, ToastContainer} from "react-toastify";

const Complaints = () => {
    const [complaint,setComplaint] = useState({
        title:"",
        subject:"",
        message:"",
        owner: ""
    })
    const [refresh,setRefresh] = useState(false);
    const [complaints,setComplaints] = useState([])
    const ctx = useContext(UserProvider);
    const user = JSON.parse(ctx.user)|| JSON.parse(localStorage.getItem('G7_li'))
    function createComplaint() {
         const com = {...complaint}
        com.owner = user._id
        AxiosInstance.post('/acc/complaints',com,{
            headers:{
                authorization: ctx.token
            }
        })
            .then((res)=>{
                toast.success(res.data.msg)
                setRefresh(!refresh)
                setComplaint({title:"",
                    subject:"",
                    message:"",
                    owner: ""})
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    }
    const getComplaints = useCallback(()=>{
        AxiosInstance.get(`/acc/complaints/${user._id}`,{
            headers:{
                authorization: ctx.token
            }
        })
            .then((res)=>{
                setComplaints(res.data.data)
                // toast.success(res.data.msg)
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    },[ctx.token, user._id])

    useEffect(()=>{
        getComplaints();
    },[getComplaints,refresh])
    return (
        <ClientLayout title={'Complaints'}>
            <ToastContainer/>
            <div className="darkCard mgT2">

                <div className="grid ">
                    <div className="grid-item2_4">
                        <div className="">
                            <div className="form-input-box">

                                <input type="text" className={'form-input'} placeholder={'Title'}
                                       value={complaint?.title||""} onChange={(e)=>{
                                    const com = {...complaint} ;
                                    com.title = e.target.value;setComplaint(com)}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid-item2_4">
                        <div className="">
                            <div className="form-input-box">
                                <input type="text" className={'form-input'} placeholder={'Subject'}  value={complaint?.subject||""} onChange={(e)=>{
                                    const com = {...complaint} ;
                                    com.subject = e.target.value;
                                    setComplaint(com)}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mgT2 form-input-box">
                    <textarea placeholder={'Your Complaint'} className={'form-textArea'} style={{height:'15rem'}}
                              value={complaint?.message||""} onChange={(e)=>{
                        const com = {...complaint} ;
                        com.message = e.target.value;setComplaint(com)}}
                    />
                </div>
                <button onClick={createComplaint} className="btn btn-primary mgT2">Send</button>
            </div>
            {
                complaints.length>0? complaints.map((e)=><div key={e._id} className="mgT2 darkCard grid">
                  <div className="grid-item1_3">

                      <p className="h7 greyText">Title</p>
                      <h6 className="h5  mgT1">{e.title}</h6>
                      <p className="h7 greyText mgT2">Subject</p>
                      <h6 className="h6 mgT1 greenText">{e.subject}</h6>

                  </div>
                    <div className="grid-item2_3">

                        <p className="h7 greyText">Message</p>
                        <p className="h6   mgT2">{e.message}</p>
                        <div className="em h7 mgT2 greenText">
                            Status: {e?.status}
                        </div>
                    </div>
                </div>):null
            }
        </ClientLayout>
    );
};

export default Complaints;